import React from "react";
import MarkDown from "../markdown/MarkDown";
import LinkRenderer from "../markdown/link-renderer";
import Form from "../Form";
import Image from "../image/Image";
import Ean13 from "../ean/Ean13";
import ServerTable from "../table/ServerTable";
import Button from "../interactive/Button";
import ReportGraph from "../reports/ReportGraph";
import KnupuCalendar from "../interactive/Calendar";
import Container from "./Container";
import propTypes from "prop-types";

function InlineContainer(props) {
  const generate = () => {
    return props.data.map((item, index) => {
      switch (item.type.toLowerCase()) {
        case "container":
          return (
            <div key={index} className="p-4">
              <Container {...item} />
            </div>
          );
        case "markdown":
          return (
            <div key={index} className="p-4">
              <MarkDown>{item.md}</MarkDown>
            </div>
          );
        case "linkdownload":
          return (
            <div key={index} className="p-4">
              <LinkRenderer download={item.filename} href={item.path}>
                {item.title}
              </LinkRenderer>
            </div>
          );
        case "form":
          return (
            <div key={index}>
              <Form key={index + Math.random()} {...item.data} />
            </div>
          );
        case "image":
          return (
            <div key={index} className="p-4">
              <Image {...item} />
            </div>
          );
        case "ean":
          return (
            <div key={index} className="p-4">
              <Ean13 {...item} />
            </div>
          );
        case "servertable":
          return (
            <div key={index}>
              <ServerTable key={item.endpoint} {...item} />
            </div>
          );
        case "button":
          return <Button key={index} id={index} type="link" {...item} />;
        case "reportgraph":
          return (
            <div key={index}>
              <ReportGraph key={item.endpoint} {...item} />
            </div>
          );
        case "calendar":
          return (
            <div key={index}>
              <KnupuCalendar key={item.endpoint} {...item} />
            </div>
          );
        default:
          return <p key={index}>Unknown type</p>;
      }
    });
  };

  return <div className="flex flex-wrap">{generate()}</div>;
}

InlineContainer.propTypes = {
  data: propTypes.array,
};
export default InlineContainer;
