import { createSlice } from "@reduxjs/toolkit";

import Cookies from "js-cookie";
import store from "../store";

export const logoutSlice = createSlice({
  name: "logout",
  initialState: {
    value: [],
  },
  reducers: {
    logout: (state, data) => {
      // Remove all site localstorage keys.
      Object.keys(window.localStorage).forEach((key) => {
        window.localStorage.removeItem(key);
      });

      const cookies = Cookies.get();
      // Remove all site cookies
      Object.keys(cookies).forEach((key) => {
        Cookies.remove(key);
      });

      setTimeout(() => {
        store.dispatch({ type: "redirect/set", payload: { content: "/" } });
      }, 100);
    },
  },
});

// Action creators are generated for each case reducer function
export const { logout } = logoutSlice.actions;

export default logoutSlice.reducer;
