import React from "react";
import { useSelector } from "react-redux";
import Notification from "./Notification";

function NotificationContainer({ ...props }) {
  const storeNotifications = useSelector((state) => state.notification.value);
  return (
    <>
      <div className="z-10 fixed top-16 right-0 mt-2 mr-2 z-75">
        {storeNotifications.map((notification, index) => {
          return (
            <Notification
              key={index}
              feel={notification.feel}
              message={notification.content}
            />
          );
        })}
      </div>
    </>
  );
}

export default NotificationContainer;
