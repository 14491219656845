import React from "react";
import ReactMarkdown from "react-markdown";
import RouterLink from "./link-renderer";
import gfm from "remark-gfm";
import gemoji from "remark-gemoji";
import propTypes from "prop-types";

function MarkDown(props) {
  return (
    <div className="overflow-clip" style={{ maxWidth: "calc(100vw - 340px)" }}>
      <ReactMarkdown
        components={{ a: RouterLink }}
        remarkPlugins={[gfm, gemoji]}
      >
        {props.children}
      </ReactMarkdown>
    </div>
  );
}

MarkDown.propTypes = {
  children: propTypes.string,
};

export default MarkDown;
