import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { nanoid } from "nanoid";
import Button from "../interactive/Button";
import { XCircle } from "react-feather";

const FieldList = ({ name, label, value, message, ...props }) => {
  const id = useRef(nanoid());
  const [list, setList] = useState(value);
  const [inputValue, setInputValue] = useState("");

  const handleUserInput = (e) => {
    setInputValue(e.target.value);
  };

  const removeFromList = (val) => {
    const newList = list.filter((value) => value !== val);
    setList(newList);
  };

  const addToList = (e) => {
    e.preventDefault();
    const newList = list;
    newList.push(inputValue);
    setList(newList);
    setInputValue("");
  };

  return (
    <label key={props.id} htmlFor={id.current} className="field">
      {label ? (
        <label htmlFor={id} className="dark:text-white">
          {label}
        </label>
      ) : null}
      <input type="hidden" value={list} name={name} id={id} />
      <div className="inline-flex gap-5">
        <Button text="Lisää" feel="primary" onClick={addToList} />
        <input
          type="text"
          id={id.current}
          value={inputValue}
          onChange={handleUserInput}
          className="dark:bg-zinc-700"
        />
      </div>
      <ul className="m-2">
        {list.map((value, key) => {
          return (
            <li key={key} className="inline-flex">
              <button onClick={() => removeFromList(value)}>
                <div className="inline-flex ml-2 text-xs items-center font-bold leading-sm uppercase bg-red-200 text-red-700 rounded-full">
                  <span className="m-1">{value}</span>
                  <XCircle className="m-0.5" />
                </div>
              </button>
            </li>
          );
        })}
      </ul>
      {message}
    </label>
  );
};

FieldList.propTypes = {
  id: PropTypes.any,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.array,
  message: PropTypes.string,
};
FieldList.defaultProps = {
  name: "FieldList",
  label: "List",
  value: [],
};

export default FieldList;
