import axios from "axios";
import Cookies from "js-cookie";
import host from "./functions/host";
import store from "./store/store";

const config = {
  timeout: 65000,
  headers: {},
};

const instance = axios.create(config);

instance.interceptors.request.use(
  function (config) {
    // console.log(config)
    // Check if baseURL is set
    if (window.localStorage.getItem("baseURL")) {
      config.baseURL = window.localStorage.getItem("baseURL") + "/api";
      // Check authentization
      if (Cookies.get(host(config.baseURL))) {
        // console.log('auth found!')
        // If Authorization found set it to headers
        config.headers.Authorization =
          "Bearer " + Cookies.get(host(config.baseURL));
        // console.log('Header length: ' + JSON.stringify(config.headers).length)
      }
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

instance.interceptors.response.use((response) => {
  // console.log(response)
  if (response.data?.view?.data?.title) {
    store.dispatch({
      type: "title/set",
      payload: response.data?.view?.data?.title,
    });
  }
  return response;
});

/** Intercept responses to get actions and add them to store */
instance.interceptors.response.use(
  (response) => {
    /** Handle actions */
    if (response.data?.actions) {
      response.data.actions.forEach((action) => handleAction(action));
    }
    return response;
  },
  (error) => {
    console.log(error);
    /** Handle error actions */
    if (error.response?.data?.actions) {
      error.response.data.actions.forEach((action) => handleAction(action));
    }
    /** Network Error. */
    if (error.message === "Network Error") {
      handleAction({
        type: "notification",
        data: {
          content: "Network Error: Ei vastausta serveriltä.",
          feel: "dangerous",
          timeout: 10000,
        },
      });
    }
    return Promise.reject(error);
  },
);

/** Handle action by its type. */
const handleAction = (action) => {
  console.log(action);
  switch (action.type) {
    case "receipt": {
      console.log("Receipt action in axios interceptor.");
      store.dispatch({ type: "notification/addAuto", payload: action.data });
      break;
    }
    case "notification": {
      console.log("Notification action in axios interceptor.");
      store.dispatch({ type: "notification/addAuto", payload: action.data });
      break;
    }
    case "redirect": {
      console.log("Redirect action in axios interceptor.");
      store.dispatch({ type: "redirect/set", payload: action.data });
      break;
    }
    case "logout": {
      console.log("Logout action in axios interceptor.");
      store.dispatch({ type: "logout/logout", payload: action.data });
      break;
    }
    default: {
      console.log("Not recognized action in axios interceptor.");
    }
  }
};

export default instance;
