import { Base64 } from "js-base64";
import sha1 from "crypto-js/sha1";
function setup(attr) {
  if (window.localStorage.getItem("setupHash")) {
    if (sha1(attr.setup) === window.localStorage.getItem("setupHash")) {
      console.log("Setup already done for this Base64.");
      return;
    }
  }
  window.localStorage.clear();
  try {
    const setup = Base64.decode(attr.setup);
    const setupObj = JSON.parse(setup);
    for (const [key, value] of Object.entries(setupObj)) {
      window.localStorage.setItem(key, value);
    }
    window.localStorage.setItem("setupHash", sha1(attr.setup));
    console.log("Setup is now initialized.");
  } catch (error) {
    console.error("Setup error when decoding Base64 to object.", error);
  }
}

export default setup;
