import React from "react";
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import store from "./store/store";
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import setup from "./setup";

import "@fontsource/source-sans-pro";
import "@fontsource/source-sans-pro/300.css";
import "@fontsource/source-sans-pro/600.css";
import "@fontsource/source-sans-pro/700.css";

/* window.knupu */
import knupu from "./knupu/knupu";

(() => knupu())(); // Secure variable scoping

/*
 * Checking if need setuping, only on root url
 */
if (window.location.pathname === "/") {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  // Check if ?setup exists and if so has some content
  if (urlParams.get("setup")) {
    setup({ setup: urlParams.get("setup") });
  }
}

const root = createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (window.localStorage.getItem("debug")) {
  reportWebVitals(console.log);
}
