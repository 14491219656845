import React from "react";
import * as Icon from "react-feather";
import propTypes from "prop-types";

function NavIcon({ icon }) {
  if (typeof icon !== "string") return "";
  if (icon.includes("-")) return <Icon.Hash className="w-5 -ml-7 mr-2" />;
  const Ico = Icon[icon.charAt(0).toUpperCase() + icon.slice(1)];

  return <Ico className="w-5 -ml-7 mr-2" />;
}

NavIcon.propTypes = {
  icon: propTypes.string,
};
export default NavIcon;
