import React, { useEffect, useState } from "react";

import { nanoid } from "nanoid";
import GraphFilters from "../interactive/GraphFilters";
import propTypes from "prop-types";

function ReportFilters({ ...props }) {
  const onFilterChange = props.onFilterChange;
  const [filters, setFilters] = useState({});
  const [filtersKey, setFilterKey] = useState(nanoid());

  const handleApply = (event) => {
    event.preventDefault();
    const form = new window.FormData(event.target);

    const fieldFilters = {};
    form.forEach(function (value, key) {
      if (!fieldFilters[key]) {
        if (value) {
          fieldFilters[key] = value;
        }
      } else {
        if (Array.isArray(fieldFilters[key])) {
          fieldFilters[key].push(value);
        } else {
          const temp = fieldFilters[key];
          fieldFilters[key] = [];
          fieldFilters[key].push(temp);
          fieldFilters[key].push(value);
        }
      }
    });
    setFilters(fieldFilters);
  };

  const restoreDefault = () => {
    setFilters({});
    setFilterKey(nanoid());
  };

  useEffect(() => {
    onFilterChange(filters);
  }, [filters, onFilterChange]);

  return (
    <div>
      <div className="invisible" onClick={restoreDefault} />
      <GraphFilters
        key={filtersKey}
        {...props.translations.filters}
        fields={props.filters}
        handleApply={handleApply}
      />
    </div>
  );
}

ReportFilters.propTypes = {
  onFilterChange: propTypes.func,
  translations: propTypes.object,
  filters: propTypes.array,
};

export default ReportFilters;
