import React, { useEffect, useState } from "react";
import Button from "../interactive/Button";
import instance from "../../axiosConfig";
import propTypes from "prop-types";

function TableActions({ body, selectable, ...props }) {
  const [actions, setActions] = useState([]);
  const [selectedList, setSelectedList] = useState([]);

  useEffect(() => {
    setActions(props.actions);
  }, [props.actions]);

  useEffect(() => {
    setSelectedList(props.selectedList);
  }, [props.selectedList]);

  const handleClick = (action) => {
    instance({
      method: props.method,
      url: action.link,
      data: selectedList,
    })
      .then((response) => {
        console.log(response);
        props.onReload();
        clearSelection();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const selectAll = () => {
    const list = [];
    body.forEach((row) => {
      list.push(row.id);
    });
    props.onSelectChange(list);
  };
  const clearSelection = () => {
    props.onSelectChange([]);
  };

  return (
    <div>
      {actions.map((action, index) => {
        if (action.type === "link") {
          return (
            <Button
              key={index}
              feel={action.feel}
              text={action.text}
              type={action.type}
              {...action}
            />
          );
        }
        return (
          <Button
            key={index}
            feel={action.feel}
            text={action.text}
            disabled={selectedList.length <= 0}
            onClick={() => handleClick(action)}
          />
        );
      })}
      {selectable && (
        <Button
          feel="primary"
          text="Vapauta valitut"
          onClick={clearSelection}
        />
      )}
      {selectable && (
        <Button feel="primary" text="Valitse kaikki" onClick={selectAll} />
      )}
    </div>
  );
}

TableActions.propTypes = {
  body: propTypes.array,
  selectable: propTypes.bool,
  actions: propTypes.array,
  selectedList: propTypes.array,
  method: propTypes.string,
  onReload: propTypes.func,
  onSelectChange: propTypes.func,
};

export default TableActions;
