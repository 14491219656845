import React, { useEffect, useState } from "react";
import FieldEmail from "../elements/fields/FieldEmail";
import instance from "../axiosConfig.js";
import { Redirect } from "react-router-dom";
import Button from "../elements/interactive/Button";

function Login() {
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [environment, setEnvironment] = useState("production");

  useEffect(() => {
    const location = new URL(window.location.href);
    if (location.hostname === "kiitoskauppa.knupu.com") {
      return setEnvironment("production");
    }
    if (location.hostname === "knupu.com") {
      return setEnvironment("production");
    }
    if (location.hostname === "localhost") {
      return setEnvironment("development");
    }
    if (location.hostname === "edge.web.knupu.dev") {
      return setEnvironment("development");
    }
    if (location.hostname === "kiitoskauppa.knupu.dev") {
      return setEnvironment("development");
    }
    if (location.hostname === "alpha.knupu.dev") {
      return setEnvironment("development");
    }
    if (location.hostname === "sesonki.knupu.dev") {
      return setEnvironment("development");
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new window.FormData(e.target);
    let instanceUrl = "https://api.kiitoskauppa.knupu.com";

    if (environment !== "production") {
      instanceUrl = formData.get("instance");
    }

    const email = formData.get("email");

    window.localStorage.setItem("baseURL", instanceUrl);
    instance.defaults.baseURL = instanceUrl + "/api";

    instance
      .post("/authentication/passwordless", {
        email,
      })
      .then(function () {
        setLoginSuccess(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div className="dark:dark-mode">
      <div className="absolute right-10 top-10">
        <Button type="link" text="Varasto viivakoodinlukija" link="/reader" />
      </div>
      <div className="absolute right-10 top-28 shadow-highlight rounded-xl">
        <a href="https://reader.kiitoskauppa.knupu.com/">
          <Button type="button" text="Varasto viivakoodinlukija V2" />
        </a>
      </div>
      <div className="flex h-screen items-center justify-center">
        <div>
          {loginSuccess && <Redirect to="/auth/otp" />}
          <h1>Knupu</h1>
          <form onSubmit={handleSubmit}>
            {/* {(environment !== 'production') && <Field name='instance' label='Instanssin osoite' required type='url' />} */}
            {environment !== "production" && (
              <div>
                <input
                  type="radio"
                  id="PRODUCTION"
                  name="instance"
                  value="https://api.kiitoskauppa.knupu.com"
                  required
                />
                <label htmlFor="PRODUCTION" className="p-2">
                  PRODUCTION
                </label>
                <br />
                <input
                  type="radio"
                  id="SESONKI"
                  name="instance"
                  value="https://sesonki.api.kiitoskauppa.knupu.dev"
                  required
                />
                <label htmlFor="SESONKI" className="p-2">
                  SESONKI
                </label>
                <br />
                <input
                  type="radio"
                  id="ALPHA"
                  name="instance"
                  value="https://alpha.api.kiitoskauppa.knupu.dev"
                  required
                />
                <label htmlFor="ALPHA" className="p-2">
                  ALPHA
                </label>
                <br />
                <input
                  type="radio"
                  id="LOCALHOST"
                  name="instance"
                  value="http://localhost:8081"
                  required
                />
                <label htmlFor="LOCALHOST" className="p-2">
                  LOCALHOST
                </label>
                <br />
                <input
                  type="radio"
                  id="LOCALHOST2"
                  name="instance"
                  value="http://127.0.0.1:8081"
                  required
                />
                <label htmlFor="LOCALHOST2" className="p-2">
                  127.0.0.1
                </label>
                <br />
              </div>
            )}
            {/* <Field name='instance' label='Instanssin osoite' type='url' /> */}

            <FieldEmail
              name="email"
              label="Sähköpostiosoite"
              required
              message="Lähetämme sinulle linkin, josta klikkaamalla pääset kirjautumaan sille laitteelle, jossa linkkiä klikkaat."
            />
            <Button type="submit" value="Submit" text="Lähetä" />
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
