import React from "react";
import { Redirect } from "react-router-dom";
import Auth from "../elements/Auth";
import instance from "../axiosConfig.js";

class Authentication extends React.Component {
  constructor(props) {
    super(props);
    this.state = { success: false };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    const data = new window.FormData(event.target);
    const success = (status) => {
      this.setState({ success: status });
    };
    instance
      .post("/authentication/passwordless", {
        email: data.get("email"),
      })
      .then(function (response) {
        success(true);
        console.log(response);
      })
      .catch(function (error) {
        success(false);
        console.log(error);
      });
  }

  render() {
    if (window.localStorage.getItem("baseURL") === null)
      return <Redirect to="/" />;
    if (this.state.success) return <Redirect to="/auth/otp" />;
    return <Auth onSubmit={this.handleSubmit} />;
  }
}

export default Authentication;
