import React from "react";
import { useRef } from "react";
import PropTypes from "prop-types";
import { nanoid } from "nanoid";

const FieldChoice = ({
  type,
  name,
  value,
  selected,
  label,
  message,
  disabled,
  required,
  ...props
}) => {
  const id = useRef(nanoid());

  if (name === undefined) {
    name = id.toString();
  }

  return (
    <label
      key={props.id}
      htmlFor={id.current}
      className="field -choice cursor-pointer dark:text-white"
    >
      <input
        id={id.current}
        name={name}
        disabled={disabled}
        required={required}
        defaultValue={value}
        defaultChecked={selected}
        type={type === "check" ? "checkbox" : "radio"}
        className="dark:bg-zinc-700"
      />
      {label && <span className="dark:text-white">{label}</span>}
    </label>
  );
};

FieldChoice.propTypes = {
  type: PropTypes.oneOf(["radio", "check"]),
  id: PropTypes.any,
  name: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  message: PropTypes.string,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};

FieldChoice.defaultProps = {
  type: "check",
  selected: false,
};

export default FieldChoice;
