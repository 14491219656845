import React from "react";
import divider from "./../../media/svg/wave-divider.svg";
import propTypes from "prop-types";

function Exterior(props) {
  return (
    <div
      className="w-screen h-screen grid col-auto max-w-full max-h-full content-center dark:dark-mode"
      style={{ gridTemplateColumns: "calc(50% - 2rem) 4rem calc(50% - 2rem)" }}
    >
      <div className="" style={{ backgroundColor: "#0066FF" }}>
        logo
      </div>
      <div className="relative h-screen max-h-full">
        <img
          src={divider}
          className="object-cover h-full w-full absolute"
          alt=""
        />
      </div>
      <div className="flex items-center justify-center">
        <div className="w-96 max-w-full p-4">
          {props.path ? <div className="mb-2">{props.path}</div> : ""}
          {props.title ? <h1 className="text-4xl my-3">{props.title}</h1> : ""}
          <div className="w-full dark:dark-mode">{props.content}</div>
        </div>
      </div>
    </div>
  );
}

Exterior.propTypes = {
  path: propTypes.string,
  title: propTypes.string,
  content: propTypes.string,
};

export default Exterior;
