import React from "react";
import { Redirect } from "react-router-dom";
import Otp from "../elements/OTP";
import instance from "../axiosConfig.js";
import Cookies from "js-cookie";
import host from "./../functions/host";
import { Base64 } from "js-base64";

class OneTimePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    const data = new window.FormData(event.target);
    const success = (status) => {
      this.setState({ success: status });
    };
    instance
      .put("/authentication/passwordless", {
        otp: data.get("otp"),
      })
      .then(function (response) {
        window.localStorage.setItem(
          "currentHost",
          host(window.localStorage.getItem("baseURL")),
        );
        Cookies.set(
          window.localStorage.getItem("currentHost"),
          response.data.token,
        );
        console.log(
          JSON.parse(Base64.decode(response.data.token.split(".")[1])),
        );
        success(true);
      })
      .catch(function (error) {
        success(false);
        console.log(error);
      });
    // TODO: Validate instance
  }

  render() {
    if (this.state.success) return <Redirect to="/" />;
    return <Otp onSubmit={this.handleSubmit} />;
  }
}

export default OneTimePassword;
