import React from "react";
import PropTypes from "prop-types";

function Blank(props) {
  return (
    <div className="dark:dark-mode">
      {props.path ? <div className="mb-2">{props.path}</div> : ""}
      <div className="my-3 flex content-center ">
        {props.title ? (
          <h1 className="text-4xl my-0 mr-1.5">{props.title}</h1>
        ) : (
          ""
        )}
        {props.actions ? props.actions : ""}
      </div>
      <div>
        <div className="w-full dark:dark-mode">{props.content}</div>
      </div>
    </div>
  );
}

Blank.propTypes = {
  path: PropTypes.array,
  title: PropTypes.string,
  content: PropTypes.array,
  actions: PropTypes.array,
};

Blank.defaultProps = {
  path: [],
  title: "",
  content: [],
  actions: [],
};

export default Blank;
