import React, { useEffect, useState } from "react";
import instance from "../axiosConfig.js";

function Reader() {
  const colors = [
    "bg-red-400",
    "bg-orange-400",
    "bg-amber-400",
    "bg-yellow-400",
    "bg-lime-400",
    "bg-green-400",
    "bg-emerald-400",
    "bg-teal-400",
    "bg-cyan-400",
    "bg-sky-400",
    "bg-blue-400",
    "bg-indigo-400",
    "bg-violet-400",
    "bg-purple-400",
    "bg-fuchsia-400",
    "bg-pink-400",
    "bg-rose-400",
  ];
  const randomColor = () => {
    return colors[Math.floor(Math.random() * colors.length - 1)];
  };
  const getColor = () => {
    if (colorMode === false) {
      return randomColor();
    }
    if (colorIndex > colors.length - 1) {
      setColorIndex(1);
      return colors[0];
    }
    setColorIndex((prevState) => {
      return prevState + 1;
    });
    return colors[colorIndex];
  };

  const [redemptions, setRedemptions] = useState([]);
  const [colorIndex, setColorIndex] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [endPoint, setEndPoint] = useState(
    "https://alpha.api.kiitoskauppa.knupu.dev",
  );
  const [colorMode, setColorMode] = useState(true);
  const [environment, setEnvironment] = useState("production");

  useEffect(() => {
    const location = new URL(window.location.href);
    if (location.hostname === "kiitoskauppa.knupu.com") {
      setEndPoint("https://api.kiitoskauppa.knupu.com");
      return setEnvironment("production");
    }
    if (location.hostname === "knupu.com") {
      return setEnvironment("production");
    }
    if (location.hostname === "localhost") {
      return setEnvironment("development");
    }
    if (location.hostname === "edge.web.knupu.dev") {
      return setEnvironment("development");
    }
    if (location.hostname === "kiitoskauppa.knupu.dev") {
      return setEnvironment("development");
    }
  }, []);

  const search = (value) => {
    console.log(environment);
    instance
      .get(endPoint + "/api/redemption/warehouse/items/" + value)
      .then((response) => {
        if (response.data.items) {
          let redemption = {};
          redemption = {
            id: value,
            status: response.data.status,
            color: getColor(),
            items: response.data.items,
          };

          setRedemptions((prevState) => [
            redemption,
            ...prevState.slice(0, 10),
          ]);
        }
        setSearchValue("");
      })
      .catch((response) => {
        let redemption = {};
        redemption = {
          id: value,
          status: "error",
          color: getColor(),
          items: [],
        };
        setRedemptions((prevState) => [redemption, ...prevState.slice(0, 10)]);
        setSearchValue("");
        console.error("Error in search", response);
      });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (searchValue === "") {
      return;
    }
    search(searchValue);
  };

  const itemBox = (redemption) => {
    return (
      <div className="h-full p-2">
        <h1>
          Lunastus: {redemption.status}, {redemption.id}{" "}
        </h1>
        {redemption.items.map((item, index) => {
          return (
            <div key={index}>
              <h1>
                {item.quantity} | {item.name} | {item.shippingMethod}
              </h1>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="flex h-full min-h-screen flex-col bg-gradient-to-t from-gray-300 to-gray-500">
      <div className="h-64 items-center justify-center">
        {environment !== "production" && (
          <div>
            <div className="absolute left-4">
              <div>
                <h2>Testi koodeja alphasta</h2>
              </div>
              <div>
                <div>
                  988003847679{" "}
                  <button
                    className="button px-0 py-0"
                    onClick={() => search("988003847679")}
                  >
                    Hae
                  </button>
                </div>
                <div>
                  988003847680{" "}
                  <button
                    className="button px-0 py-0"
                    onClick={() => search("988003847680")}
                  >
                    Hae
                  </button>
                </div>
                <div>
                  988003847681{" "}
                  <button
                    className="button px-0 py-0"
                    onClick={() => search("988003847681")}
                  >
                    Hae
                  </button>
                </div>
                <div>
                  988003847682{" "}
                  <button
                    className="button px-0 py-0"
                    onClick={() => search("988003847682")}
                  >
                    Hae
                  </button>
                </div>
                <div>
                  988003847683{" "}
                  <button
                    className="button px-0 py-0"
                    onClick={() => search("988003847683")}
                  >
                    Hae
                  </button>
                </div>
                <div>
                  988003847684{" "}
                  <button
                    className="button px-0 py-0"
                    onClick={() => search("988003847684")}
                  >
                    Hae
                  </button>
                </div>
                <div>
                  988003847685{" "}
                  <button
                    className="button px-0 py-0"
                    onClick={() => search("988003847685")}
                  >
                    Hae
                  </button>
                </div>
                <div>
                  988003847686{" "}
                  <button
                    className="button px-0 py-0"
                    onClick={() => search("988003847686")}
                  >
                    Hae
                  </button>
                </div>
                <div>
                  988003847687{" "}
                  <button
                    className="button px-0 py-0"
                    onClick={() => search("988003847687")}
                  >
                    Hae
                  </button>
                </div>
              </div>
            </div>
            <div className="absolute right-4">
              <div>
                <input
                  type="radio"
                  id="PRODUCTION"
                  name="instance"
                  value="https://api.kiitoskauppa.knupu.com"
                  checked={endPoint === "https://api.kiitoskauppa.knupu.com"}
                  onChange={(e) =>
                    setEndPoint("https://api.kiitoskauppa.knupu.com")
                  }
                />
                <label htmlFor="PRODUCTION" className="p-2">
                  PRODUCTION
                </label>
                <input
                  type="radio"
                  id="ALPHA"
                  name="instance"
                  value="https://alpha.api.kiitoskauppa.knupu.dev"
                  checked={
                    endPoint === "https://alpha.api.kiitoskauppa.knupu.dev"
                  }
                  onChange={(e) =>
                    setEndPoint("https://alpha.api.kiitoskauppa.knupu.dev")
                  }
                />
                <label htmlFor="ALPHA" className="p-2">
                  ALPHA
                </label>
                <input
                  type="radio"
                  id="LOCALHOST"
                  name="instance"
                  value="http://127.0.0.1:8081"
                  checked={endPoint === "http://127.0.0.1:8081"}
                  onChange={(e) => setEndPoint("http://127.0.0.1:8081")}
                />
                <label htmlFor="LOCALHOST" className="p-2">
                  LOCALHOST
                </label>
                <br />
                <input
                  type="radio"
                  id="random-colors"
                  name="colors"
                  value="false"
                  checked={colorMode === false}
                  onChange={(e) => setColorMode(false)}
                />
                <label htmlFor="random-colors" className="p-2">
                  Random Colors
                </label>
                <input
                  type="radio"
                  id="sec-colors"
                  name="colors"
                  value="true"
                  checked={colorMode === true}
                  onChange={(e) => setColorMode(true)}
                />
                <label htmlFor="sec-colors" className="p-2">
                  Sequential Colors
                </label>
              </div>
            </div>
          </div>
        )}
        <div className="flex h-full items-center justify-center">
          <form onSubmit={handleSubmit}>
            <input
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              type="text"
              name="code"
              id="code"
              autoFocus
              className="outline-none p-4 block w-full rounded-lg border border-gray-300 text-xl focus:outline-none focus:ring-4 focus:ring-blue-600"
            />
          </form>
        </div>
      </div>
      <div className="grow flex flex-nowrap flex-col overflow-hidden h-1/2">
        {redemptions.map((redemption, index) => {
          return (
            <div
              key={index}
              className={redemption.color + " m-1 mx-4 mr-4 rounded-md"}
            >
              {itemBox(redemption)}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Reader;
