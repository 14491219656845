import React from "react";
import PropTypes from "prop-types";
import * as Icon from "react-feather";

function Pagination({ perpage, meta, ...props }) {
  const currentPage = Math.ceil(meta.from / perpage);
  const pages = Math.ceil(meta.total / perpage);

  const handleClick = (e, index) => {
    e.preventDefault();
    props.onPageChange(index);
  };

  /**
   * Generates +/- 3 pages from current page and filters out too big or too small ones.
   */
  const pageButton = () => {
    let buttons = [];

    buttons.push(currentPage - 3);
    buttons.push(currentPage - 2);
    buttons.push(currentPage - 1);
    buttons.push(currentPage);
    buttons.push(currentPage + 1);
    buttons.push(currentPage + 2);
    buttons.push(currentPage + 3);

    /* Filter out negative pages */
    buttons = buttons.filter((button) => {
      return button > 0;
    });

    /* Filter out too big pages */
    buttons = buttons.filter((button) => {
      return button <= pages;
    });
    return buttons;
  };

  return (
    <nav
      className="relative inline-flex rounded-md shadow-sm -space-x-px"
      aria-label="Pagination"
    >
      <button
        className="-pagination -gable dark:bg-zinc-700 dark:hover:bg-zinc-800 dark:text-white dark:hover:text-white"
        onClick={(e) => handleClick(e, 0)}
      >
        <span className="sr-only">First</span>
        <Icon.ChevronsLeft />
      </button>
      <button
        className="-pagination dark:bg-zinc-700 dark:hover:bg-zinc-800 dark:text-white dark:hover:text-white"
        onClick={(e) =>
          handleClick(e, currentPage > 1 ? currentPage - 1 : currentPage)
        }
      >
        <span className="sr-only">Previous</span>
        <Icon.ChevronLeft />
      </button>
      {pageButton().map((button, index) => {
        return (
          <button
            className={
              "-pagination dark:bg-zinc-700 dark:hover:bg-zinc-800 dark:text-white dark:hover:text-white " +
              (button === currentPage ? "-current" : "")
            }
            key={index}
            onClick={(e) => handleClick(e, button)}
          >
            <span>{button}</span>
          </button>
        );
      })}
      <button
        className="-pagination dark:bg-zinc-700 dark:hover:bg-zinc-800 dark:text-white dark:hover:text-white"
        onClick={(e) =>
          handleClick(e, currentPage < pages ? currentPage + 1 : currentPage)
        }
      >
        <span className="sr-only">Next</span>
        <Icon.ChevronRight />
      </button>
      <button
        className="-pagination -gable -end dark:bg-zinc-700 dark:hover:bg-zinc-800 dark:text-white dark:hover:text-white"
        onClick={(e) => handleClick(e, pages)}
      >
        <span className="sr-only">Last</span>
        <Icon.ChevronsRight />
      </button>
    </nav>
  );
}

Pagination.propTypes = {
  meta: PropTypes.object,
  perpage: PropTypes.number,
  onPageChange: PropTypes.func,
};

Pagination.defaultProps = {
  meta: {
    total: 0,
    from: 0,
    to: 0,
  },
  perpage: 50,
  onPageChange: (index) => {
    console.log(index);
  },
};

export default Pagination;
