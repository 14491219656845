import React from "react";
import { useEffect, useState } from "react";
import instance from "../../axiosConfig";
import PropTypes from "prop-types";
import {
  VictoryArea,
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryGroup,
  VictoryTheme,
  VictoryTooltip,
} from "victory";
import Button from "../interactive/Button";

function StockDepartedGraph(props) {
  const [open, setOpen] = useState(false);
  const [selectedYear, setSelectedYear] = useState(0);
  const [type, setType] = useState("bar");
  const [all, setAll] = useState(false);
  const [years, setYears] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    instance
      .get(props.endpoint)
      .then(function (response) {
        if (response.data.parsedData) {
          setData(response.data.parsedData);
        }
        if (response.data.years) {
          setYears(response.data.years);
          setSelectedYear(response.data.years[0]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [props.endpoint]);

  const yearsList = years.map((year, index) => {
    let feel = "secondary";
    if (year === selectedYear) {
      feel = "green";
    }
    if (all) {
      feel = "secondary";
    }
    return (
      <Button
        key={index}
        feel={feel}
        onClick={(e) => {
          e.preventDefault();
          setSelectedYear(year);
          if (all) {
            setAll(!all);
          }
        }}
        text={year.toString()}
      ></Button>
    );
  });

  const allYearsBarChard = () => {
    if (all) {
      if (type === "area") {
        return (
          <VictoryChart theme={VictoryTheme.material} height={500} width={500}>
            <VictoryGroup>
              {years.toReversed().map((year) => {
                return yearInAreaChardNoStyle(year);
              })}
            </VictoryGroup>
            <VictoryAxis
              dependentAxis
              style={{
                tickLabels: { fill: "#ffffff" },
                axisLabel: { fill: "#ffffff", padding: 30 },
              }}
            />
            <VictoryAxis
              label="Kuukausi"
              tickValues={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
              style={{
                tickLabels: { fill: "#ffffff" },
                axisLabel: { fill: "#ffffff", padding: 30 },
              }}
            />
          </VictoryChart>
        );
      }
      if (type === "bar") {
        return (
          <VictoryChart theme={VictoryTheme.material} height={500} width={500}>
            <VictoryGroup offset={6} colorScale={["tomato", "orange", "gold"]}>
              {years.toReversed().map((year) => {
                return yearInBarChardNoStyle(year);
              })}
            </VictoryGroup>
            <VictoryAxis
              dependentAxis
              style={{
                tickLabels: { fill: "#ffffff" },
                axisLabel: { fill: "#ffffff", padding: 30 },
              }}
            />
            <VictoryAxis
              label="Kuukausi"
              tickValues={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
              style={{
                tickLabels: { fill: "#ffffff" },
                axisLabel: { fill: "#ffffff", padding: 30 },
              }}
            />
          </VictoryChart>
        );
      }
    }
    return <></>;
  };

  const singleYearChart = () => {
    if (!all) {
      if (type === "area") {
        return (
          <VictoryChart theme={VictoryTheme.material} height={500} width={500}>
            {yearInAreaChard(selectedYear)}
            <VictoryAxis
              dependentAxis
              style={{
                tickLabels: { fill: "#ffffff" },
                axisLabel: { fill: "#ffffff", padding: 30 },
              }}
            />
            <VictoryAxis
              label="Kuukausi"
              tickValues={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
              style={{
                tickLabels: { fill: "#ffffff" },
                axisLabel: { fill: "#ffffff", padding: 30 },
              }}
            />
          </VictoryChart>
        );
      }
      if (type === "bar") {
        return (
          <VictoryChart theme={VictoryTheme.material} height={500} width={500}>
            {yearInBarChard(selectedYear)}
            <VictoryAxis
              dependentAxis
              style={{
                tickLabels: { fill: "#ffffff" },
                axisLabel: { fill: "#ffffff", padding: 30 },
              }}
            />
            <VictoryAxis
              label="Kuukausi"
              tickValues={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
              style={{
                tickLabels: { fill: "#ffffff" },
                axisLabel: { fill: "#ffffff", padding: 30 },
              }}
            />
          </VictoryChart>
        );
      }
    }
    return <></>;
  };

  const yearInBarChardNoStyle = (year) => {
    return (
      <VictoryBar
        key={year}
        labelComponent={<VictoryTooltip />}
        data={data[year]}
        animate={{
          onLoad: { duration: 1000 },
          onExit: { duration: 1000 },
        }}
      />
    );
  };
  const yearInBarChard = (year) => {
    return (
      <VictoryBar
        key={year}
        style={{ data: { fill: "gold" } }}
        labelComponent={<VictoryTooltip />}
        data={data[year]}
        animate={{
          onLoad: { duration: 1000 },
          onExit: { duration: 1000 },
        }}
      />
    );
  };

  const yearInAreaChardNoStyle = (year) => {
    return (
      <VictoryArea
        key={year}
        labelComponent={<VictoryTooltip />}
        data={data[year]}
        animate={{
          onLoad: { duration: 1000 },
          onExit: { duration: 1000 },
        }}
      />
    );
  };
  const yearInAreaChard = (year) => {
    return (
      <VictoryArea
        key={year}
        labelComponent={<VictoryTooltip />}
        style={{
          data: { fill: "gold" },
        }}
        data={data[year]}
        animate={{
          onLoad: { duration: 1000 },
          onExit: { duration: 1000 },
        }}
      />
    );
  };

  if (!open) {
    return (
      <div className="absolute top-24 right-6">
        <Button onClick={() => setOpen(!open)} text="Menekki"></Button>
      </div>
    );
  }

  return (
    <div className="absolute top-14 right-6 bg-opacity-100 bg-gray-600">
      <div>
        <div className="absolute top-2 right-2 z-10">
          <Button
            onClick={() => setAll(!all)}
            feel={all ? "green" : "secondary"}
            text="Kaikki vuodet"
          ></Button>
          <Button
            onClick={() => setType("bar")}
            feel={type === "bar" ? "green" : "secondary"}
            text="Bar"
          ></Button>
          <Button
            onClick={() => setType("area")}
            feel={type === "area" ? "green" : "secondary"}
            text="Area"
          ></Button>
          <Button
            onClick={() => setOpen(!open)}
            feel="danger"
            text="Sulje"
          ></Button>
        </div>
        <div className="absolute top-2 left-2 z-10">{yearsList}</div>
        <div style={{ height: "800px", width: "800px" }}>
          {allYearsBarChard()}
          {singleYearChart()}
        </div>
      </div>
    </div>
  );
}

StockDepartedGraph.propTypes = {
  endpoint: PropTypes.string.isRequired,
};

StockDepartedGraph.defaultProps = {
  endpoint: "",
};

export default StockDepartedGraph;
