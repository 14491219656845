import React from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useState, useEffect } from "react";
import Button from "./Button";
import instance from "../../axiosConfig.js";
import propTypes from "prop-types";

function KnupuCalendar({ ...props }) {
  const [value, onChange] = useState(new Date());
  const [datesToAddClassTo, setDates] = useState([]);

  useEffect(() => {
    if (datesToAddClassTo.length === 0) {
      let i = 0;
      const dates = [];

      while (i < props.highlightedDates.length) {
        if (Number.isInteger(parseInt(props.highlightedDates[i]))) {
          dates.push(new Date(parseInt(props.highlightedDates[i]) * 1000));
        }
        i++;
      }
      setDates(dates);
    }
  }, []);

  function eventSetdatesToAddClassTo(date) {
    function dateIsInArray(array, value) {
      return !!array.find((item) => {
        return item.getTime() === value.getTime();
      });
    }

    if (dateIsInArray(datesToAddClassTo, date)) {
      setDates(datesToAddClassTo.filter((a) => a.getTime() !== date.getTime()));
    } else {
      setDates([...datesToAddClassTo, date]);
    }
  }

  function tileClassName({ date, view }) {
    function isSameDay(dDate, date2) {
      dDate.setHours(0, 0, 0, 0);
      date2.setHours(0, 0, 0, 0);
      return dDate.getTime() === date2.getTime();
    }
    // Add class to tiles in month view only
    if (view === "month") {
      if (datesToAddClassTo.find((dDate) => isSameDay(dDate, date))) {
        return "react-calendar__tile--disabled";
      }
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (datesToAddClassTo) {
      save(datesToAddClassTo);
    }
  };

  const save = (dates) => {
    const uri = props.endpoint;
    instance
      .put(uri, { dates })
      .then((response) => {
        // console.log(response.data)
      })
      .catch((response) => {
        console.error("Error", response);
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Calendar
          locale="fi-FI"
          onChange={onChange}
          tileClassName={tileClassName}
          className="dark:bg-zinc-700 dark:text-white"
          value={value}
          onClickDay={(date) => eventSetdatesToAddClassTo(date)}
        />
        <div className="my-2">
          <Button feel="primary" type="submit" value="Submit" text="Tallenna" />
        </div>
      </form>
    </>
  );
}

KnupuCalendar.propTypes = {
  endpoint: propTypes.string,
  highlightedDates: propTypes.array,
};

export default KnupuCalendar;
