import React from "react";
import propTypes from "prop-types";

function Notification({ ...props }) {
  const message = props.message ?? "Successfully Saved!";
  let color;
  switch (props.feel) {
    case "primary":
      color = "#44C997";
      break;
    case "secondary":
      color = "#615bd4";
      break;
    case "warning":
      color = "#fff3cd";
      break;
    case "danger":
      color = "#ef4f4f";
      break;
    case "dangerous":
      color = "#ef4f4f";
      break;
    default:
      color = "#44C997";
  }
  return (
    <div
      className="rounded-lg border-gray-300 border p-3 shadow-lg m-1"
      style={{ backgroundColor: color }}
    >
      <div className="flex flex-row">
        <div className="ml-2 mr-6">
          <span className="font-semibold">{message}</span>
        </div>
      </div>
    </div>
  );
}

Notification.propTypes = {
  feel: propTypes.string,
  message: propTypes.string,
};

export default Notification;
