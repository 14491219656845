import React from "react";
import PropTypes from "prop-types";

import "./Navigation.css";

import { Link } from "react-router-dom";

function NavImage({ source, alt, link, size }) {
  return (
    <li className={size === "half" ? " w-3/6 inline-block p-2" : " block m-2"}>
      {link && (
        <Link to={link}>
          <img alt={alt} src={source} className="w-full max-h-24" />
        </Link>
      )}
      {!link && <img alt={alt} src={source} className="w-full max-h-24" />}
    </li>
  );
}
NavImage.propTypes = {
  size: PropTypes.string,
  source: PropTypes.string,
  alt: PropTypes.string,
  link: PropTypes.string,
};

NavImage.defaultProps = {
  size: "half",
  source: "https://upload.wikimedia.org/wikipedia/commons/0/06/AGPLv3_Logo.svg",
  alt: "Logo",
  link: "https://selusta.com",
};

export default NavImage;
