import { createSlice } from "@reduxjs/toolkit";

const loadingSlice = createSlice({
  name: "loading",
  initialState: {
    value: false,
  },
  reducers: {
    loading: (state) => {
      state.value = true;
    },
    done: (state) => {
      state.value = false;
    },
  },
});

export const { loading, done } = loadingSlice.actions;

export default loadingSlice.reducer;
