import React from "react";
import { nanoid } from "nanoid";
import logo from "./../../media/logo/logo-green.png";
import { useEffect, useState } from "react";
import instance from "../../axiosConfig";
import PropTypes from "prop-types";

function BudbeeWidget(props) {
  const [show, setShow] = useState(false);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    if (show) {
      instance
        .get(props.endpoint)
        .then(function (response) {
          if (response.data.events) {
            setEvents(response.data.events);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [show]);

  const eventItem = events.map((event) => {
    console.log(event);
    const date = new Date(parseInt(event.doc.createdAt) * 1000);

    return (
      <tr key={event.id}>
        <td className="p-1">{event.doc.body.event}</td>
        <td className="p-1">{date.toLocaleString("fi-FI")}</td>
        <td className="p-1">{event.doc.body.eventData.packageId}</td>
      </tr>
    );
  });

  return (
    <div>
      <div className="absolute top-14 right-0">
        <div key={nanoid()} onClick={() => setShow(!show)}>
          <img src={logo} alt="" width="60" height="40" />
        </div>
      </div>
      {show && (
        <div className="absolute top-30 right-0 border-2 border-white">
          {events.length < 1 && (
            <div className="p-4">Ei Budbee tapahtumia.</div>
          )}
          {events.length > 0 && (
            <div>
              <table>
                <thead>
                  <tr>
                    <th>Tapahtuma</th>
                    <th>Aika</th>
                    <th>Paketin ID</th>
                  </tr>
                </thead>
                <tbody>{eventItem}</tbody>
              </table>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

BudbeeWidget.propTypes = {
  endpoint: PropTypes.string.isRequired,
};

BudbeeWidget.defaultProps = {
  endpoint: "",
};

export default BudbeeWidget;
