import React from "react";
import Modal from "./../interactive/Modal";
import propTypes from "prop-types";

function Loading(props) {
  return (
    <div
      className="w-full h-full min-h-full flex items-center justify-center pt-4 px-4 pb-20 text-center dark:dark-mode"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <Modal {...props.modal} />
    </div>
  );
}

Loading.propTypes = {
  modal: propTypes.object,
};

export default Loading;
