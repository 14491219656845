import React, { useEffect, useState } from "react";
import { nanoid } from "nanoid";
import * as Icon from "react-feather";
import PropTypes from "prop-types";

const Field = ({
  type,
  name,
  value,
  label,
  message,
  disabled,
  required,
  min,
  max,
  step,
  rows,
  ...props
}) => {
  const id = nanoid();
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState("");

  const [realRows, setRealRows] = useState(rows);

  const handleChange = (event) => {
    if (type === "checkbox") {
      console.log(event.target.checked);
      setInput(event.target.checked);
      value = event.target.checked;
    } else {
      setInput(event.target.value);
    }
  };

  useEffect(() => {
    if (open) {
      setRealRows(16);
    }
    if (!open) {
      setRealRows(rows);
    }
  }, [open]);

  useEffect(() => {
    if (value === undefined) {
      return;
    }
    setInput((value.toString() + "").trim());
  }, [value]);

  const fieldStyle = () => {
    if (input.length <= 0) {
      if (required && required === true) {
        return "field-error";
      }
      return "field-warning";
    }
    return "field-success";
  };

  if (type === "textarea") {
    return (
      <div className={`field ${fieldStyle()}`} key={props.id}>
        <div className="relative">
          {label ? (
            <label htmlFor={id} className="dark:text-white">
              {label}
            </label>
          ) : null}
          <div className="absolute top-0 right-1">
            <div className="flex">
              {open && <Icon.MinusCircle onClick={() => setOpen(false)} />}
              {!open && <Icon.PlusCircle onClick={() => setOpen(true)} />}
            </div>
          </div>
        </div>
        <textarea
          id={id}
          defaultValue={value}
          name={name}
          rows={realRows}
          maxLength={max}
          disabled={disabled}
          required={required}
          onChange={handleChange}
          className="dark:bg-zinc-700"
        />
        {message ? <p className="dark:text-white">{message}</p> : null}
      </div>
    );
  }

  if (type === "checkbox") {
    return (
      <div className={`field ${fieldStyle()}`} key={props.id}>
        <input type="hidden" value={input} name={name} id={props.id} />
        {label ? (
          <label htmlFor={id} className="dark:text-white">
            {label}
          </label>
        ) : null}
        <input
          id={id}
          defaultValue={value}
          type={type}
          disabled={disabled}
          required={required}
          onChange={handleChange}
          defaultChecked={props.checked}
          className="dark:bg-zinc-700"
        />
        {message ? <p className="dark:text-white">{message}</p> : null}
      </div>
    );
  }

  if (type === "date") {
    return (
      <div className={`field ${fieldStyle()}`} key={props.id}>
        {label ? (
          <label htmlFor={id} className="dark:text-white">
            {label}
          </label>
        ) : null}
        <input
          id={id}
          defaultValue={value}
          type={type}
          name={name}
          min={min}
          max={max}
          step={step}
          disabled={disabled}
          required={required}
          onChange={handleChange}
          className="dark:bg-zinc-700"
        />
        {message ? <p className="dark:text-white">{message}</p> : null}
      </div>
    );
  }

  return (
    <div className={`field ${fieldStyle()}`} key={props.id}>
      {label ? (
        <label htmlFor={id} className="dark:text-white">
          {label}
        </label>
      ) : null}
      <input
        id={id}
        defaultValue={value}
        type={type}
        name={name}
        min={min}
        max={max}
        step={step}
        disabled={disabled}
        required={required}
        onChange={handleChange}
        className="dark:bg-zinc-700"
      />
      {message ? <p className="dark:text-white">{message}</p> : null}
    </div>
  );
};

Field.propTypes = {
  type: PropTypes.oneOf([
    "hidden",
    "text",
    "textarea",
    "tel",
    "email",
    "password",
    "url",
    "file",
    "number",
    "date",
    "month",
    "week",
    "datetime",
    "color",
    "datetime-local",
    "checkbox",
  ]),
  id: PropTypes.any,
  name: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  message: PropTypes.string,
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  checked: PropTypes.bool,
};

Field.defaultProps = {
  type: "text",
};

export default Field;
