import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Authentication from "./views/Authentication";
import OneTimePassword from "./views/OneTimePassword";
import Page from "./views/Page";
import Login from "./views/Login";
import Reader from "./views/Reader";
import { useSelector } from "react-redux";

function App() {
  const loadingState = useSelector((state) => state.loading.value);

  return (
    <Router>
      {loadingState ? (
        <div className="fullscreen-loading fixed top-0 left-0 w-screen h-1 z-50" />
      ) : null}
      <Switch>
        <Route exact path="/login">
          <Login />
        </Route>
        <Route exact path="/reader">
          <Reader />
        </Route>
        <Route exact path="/auth">
          <Authentication />
        </Route>
        <Route exact path="/auth/otp">
          <OneTimePassword />
        </Route>
        <Route path="/">
          <Page />
        </Route>
        <Route>
          <Page />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
