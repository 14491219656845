import React from "react";
import { nanoid } from "nanoid";
import PropTypes from "prop-types";
import * as Icon from "react-feather";
import tldExtract from "tld-extract";

class FieldEmail extends React.Component {
  constructor(props) {
    super(props);
    this.id = nanoid();
    this.state = {
      value: this.props.value,
      fails: false,
      status: {
        isValid: true,
        isOfficialTLD: true,
      },
    };
    this.message = this.props.message ? (
      <p className="text-sm">{this.props.message}</p>
    ) : null;

    this.handleChange = this.handleChange.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.getStatus = this.getStatus.bind(this);

    this.validEmail =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  }

  handleChange(event) {
    let isOfficialTLD = false;
    try {
      tldExtract(`https://${event.target.value.split("@").pop()}`);
      isOfficialTLD = true;
    } catch (error) {
      if (window.localStorage.getItem("debug")) {
        console.error(error);
      }
    }
    this.setState({
      value: event.target.value,
      fails: !(this.validEmail.test(event.target.value) && isOfficialTLD),
      status: {
        isValid: this.validEmail.test(event.target.value),
        isOfficialTLD,
      },
    });
  }

  handleFocus(event) {
    this.setState({
      focusClass:
        "border-l border-indigo-400 ring ring-indigo-300 ring-opacity-50",
    });
  }

  handleBlur(event) {
    if (this.props.required && this.state.status.isValid === false) {
      this.setState({
        focusClass: "border-l border-red-400 ring ring-red-300 ring-opacity-50",
      });
    } else {
      this.setState({ focusClass: null });
    }
  }

  getStatus(props) {
    if (props.isValid === false) {
      return <Icon.AlertOctagon className="h-5 text-red-500" />;
    }
    if (props.isOfficialTLD === false) {
      return <Icon.AlertCircle className="h-4 text-yellow-500" />;
    }
    // TODO: Tell to user what does these alerts means.
  }

  render() {
    return (
      <div className="block mt-2" key={this.props.id}>
        {this.props.label ? (
          <label htmlFor={this.id}>{this.props.label}</label>
        ) : null}
        <div
          className={
            "flex flex-initial shadow-sm my-1.5 border-l rounded-md " +
            this.state.focusClass
          }
        >
          <input
            id={this.id}
            type="email"
            name={this.props.name}
            disabled={this.props.disabled}
            required={this.props.required}
            value={this.state.value}
            onInput={this.handleChange}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            className="outline-none py-1 pr-0 px-2 block w-full rounded-l-md border border-r-0 border-gray-300 disabled:bg-gray-300 disabled:opacity-50 disabled:cursor-not-allowed dark:bg-zinc-700"
          />
          <div
            className={
              "grid place-items-center py-1 px-2 block w-min rounded-r-md border border-l-0 border-gray-300 " +
              (this.props.disabled
                ? "bg-gray-300 opacity-50 cursor-not-allowed"
                : null)
            }
          >
            {this.state.fails ? this.getStatus(this.state.status) : null}
          </div>
        </div>
        {this.message}
      </div>
    );
  }
}

FieldEmail.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  indexKey: PropTypes.any,
  value: PropTypes.string,
  name: PropTypes.string,
  message: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};

FieldEmail.defaultProps = {
  label: "Input Email",
  value: "",
  name: null,
  message: null,
  disabled: false,
  required: false,
};

export default FieldEmail;
