import React from "react";
import PropTypes from "prop-types";
import Field from "./fields/Field";
import FieldEmail from "./fields/FieldEmail";
import FieldPhone from "./fields/FieldPhone";
import FieldChoice from "./fields/FieldChoice";
import FieldChoices from "./fields/FieldChoices";
import FieldSearch from "./fields/FieldSearch";
import FieldFile from "./fields/FieldFile";
import FieldExcel from "./fields/FieldExcel";
import FieldList from "./fields/FieldList";
import FieldDragAndDropList from "./fields/FieldDragAndDropList";
import FieldMarkDown from "./fields/FieldMarkDown";
import FieldListSelect from "./fields/FieldListSelect";
import FieldSelectSearch from "./fields/FieldSelectSearch";
import { useState } from "react";

function FormFields(props) {
  const [activeTab, setActiveTab] = useState("");
  const toggleActiveTab = (tabName) => {
    setActiveTab(tabName);
  };
  return props.fields.map((field, index) => {
    switch (field.type.toLowerCase()) {
      case "field":
        return (
          <Field
            id={index}
            key={index.toString() + field.data.value}
            {...field.data}
          />
        );
      case "markdownfield":
        return (
          <FieldMarkDown
            id={index}
            key={index.toString() + field.data.value}
            {...field.data}
          />
        );
      case "filefield":
        return (
          <FieldFile
            id={index}
            key={index.toString() + field.data.value}
            {...field.data}
          />
        );
      case "excelfield":
        return (
          <FieldExcel
            id={index}
            key={index.toString() + field.data.value}
            {...field.data}
          />
        );
      case "emailfield":
        return (
          <FieldEmail
            id={index}
            key={index.toString() + field.data.value}
            {...field.data}
          />
        );
      case "phonefield":
        return (
          <FieldPhone
            id={index}
            key={index.toString() + field.data.value}
            {...field.data}
          />
        );
      case "choice":
        return (
          <FieldChoice
            id={index}
            key={index.toString() + field.data.value}
            {...field.data}
          />
        );
      case "listfield":
        return <FieldList id={index} key={index.toString()} {...field.data} />;
      case "listselectfield":
        return (
          <FieldListSelect id={index} key={index.toString()} {...field.data} />
        );
      case "choices":
        return (
          <FieldChoices
            id={index}
            key={index.toString() + field.data.value}
            {...field.data}
          />
        );
      case "searchfield":
        return (
          <FieldSearch
            id={index}
            key={index.toString() + field.data.value}
            {...field.data}
          />
        );
      case "searchselectfield":
        return (
          <FieldSelectSearch
            id={index}
            key={index.toString() + field.data.value}
            {...field.data}
          />
        );
      case "draganddropfield":
        return (
          <FieldDragAndDropList
            id={index}
            key={index.toString() + field.data.value}
            {...field.data}
          />
        );
      case "datetimefield":
        return (
          <Field
            id={index}
            key={index.toString() + field.data.value}
            {...field.data}
          />
        );
      case "daterangetogglefield": {
        const temp = field.data.choices.map((field, index) => {
          return (
            <li className="mr-2 list-none" key={index.toString() + field.value}>
              <a
                href="#"
                className="inline-block pr-4 pt-4 pb-4 border-b-2 border-transparent rounded-t-lg hover:border-primary text-gray-950 hover:text-gray-950 hover:no-underline"
                onClick={() => toggleActiveTab("")}
              >
                <FieldChoice
                  id={index}
                  key={index.toString() + field.value}
                  type="radio"
                  name="range"
                  {...field}
                />
              </a>
            </li>
          );
        });

        const custom = {
          label: "Vapaavalintainen",
          value: "customrange",
          selected: false,
        };

        temp.push(
          <li className="mr-2 list-none" key={index.toString() + field.value}>
            <a
              href="#"
              className="inline-block pr-4 pt-4 pb-4 border-b-2 border-transparent rounded-t-lg hover:border-primary text-gray-950 hover:text-gray-950 hover:no-underline"
              onClick={() => toggleActiveTab("customrange")}
            >
              <FieldChoice
                id="4"
                key={"4" + custom.value}
                type="radio"
                name="range"
                {...custom}
              />
            </a>
          </li>,
        );

        const temp2 = field.data.dateRangeFields.map((field, index) => {
          return (
            <div className="py-4" key={index}>
              <Field
                id={index}
                key={index.toString() + field.data.value}
                {...field.data}
              />
            </div>
          );
        });

        const customRangeSelector = (
          <div className="w-64">
            <div className="flex items-stretch space-x-4">{temp2}</div>
          </div>
        );

        return (
          <div className="field mb-3" key={index}>
            <label className="dark:text-white">{field.data.label}</label>
            <ul className="flex flex-wrap -mb-px">{temp}</ul>
            <div className="flex items-stretch">
              {activeTab === "customrange" ? customRangeSelector : ""}
            </div>
          </div>
        );
      }
      default:
        console.error(`Unknow field type: ${field.type}`);
        return null;
    }
  });
}

FormFields.propTypes = {
  fields: PropTypes.array,
};

FormFields.defaultProps = {
  fields: [],
};

export default FormFields;
