import React from "react";
import { useSelector } from "react-redux";
import * as Icon from "react-feather";
import store from "../../store/store";

const NotificationTable = () => {
  const storeNotifications = useSelector((state) => state.notification.history);

  const close = () => {
    store.dispatch({ type: "notification/toggleModal" });
  };

  return (
    <div className="bg-gray-50 p-8 dark:bg-zinc-700">
      <div className="absolute right-2 top-2" onClick={close}>
        <Icon.X className="w-5 -ml-7 mr-2" />
      </div>
      <table className="w-full">
        <thead className="w-full flex pl-9">
          <tr className="w-full md-8">
            <th className="w-3/4">Ilmoitus</th>
            <th className="w-1/4">Pvm / Kellonaika</th>
          </tr>
        </thead>
        <tbody
          className="flex flex-col items-center overflow-y-scroll w-full"
          style={{ height: "70vh" }}
        >
          {storeNotifications
            .slice(0)
            .reverse()
            .map((notification, index) => {
              const date = new Date(notification.time).toLocaleString("fi-FI");
              return (
                <tr key={index} className="w-full md-4">
                  <td className="pl-2 w-3/4">{notification.content}</td>
                  <td className="pl-2 w-1/4">{date}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default NotificationTable;
