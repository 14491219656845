import React from "react";

import Column from "./Column";
import propTypes from "prop-types";

function Row({ header, selectable, editable, ...props }) {
  return (
    <tr>
      {selectable && <Column title="" />}
      {header.map((column, index) => {
        return (
          <Column
            key={index}
            name={column.name}
            title={column.title}
            sortable={column.sortable}
            colspan={column.colspan}
            {...props}
          />
        );
      })}
      {editable && <Column title="" />}
    </tr>
  );
}

Row.propTypes = {
  header: propTypes.array,
  selectable: propTypes.bool,
  editable: propTypes.bool,
};

Row.defaultProps = {
  header: [],
  selectable: false,
  editable: false,
  onSortChange: () => {},
};

export default Row;
