import React from "react";
import { useCallback } from "react";
import PropTypes from "prop-types";
import { nanoid } from "nanoid";

import FieldChoice from "./FieldChoice";

const FieldChoices = ({
  type,
  name,
  choices,
  label,
  message,
  disabled,
  required,
  ...props
}) => {
  const id = nanoid();
  const choice = useCallback(() => {
    switch (type) {
      case "select": {
        const defaultValue = choices.find((choice) => choice.selected);
        return (
          <select
            name={name}
            id={id}
            disabled={disabled}
            required={required}
            multiple={props.multiple}
            defaultValue={defaultValue ? defaultValue.value : null}
            className="dark:bg-zinc-700"
          >
            {choices.map((choice, index) => (
              <option
                key={`${index}-${choice.value}`}
                value={choice.value}
                disabled={choice.disabled}
              >
                {choice.label}
              </option>
            ))}
          </select>
        );
      }
      default:
        return (
          <div id={id} className="pt-1.5 pl-1.5">
            {choices.map((choice, index) => (
              <FieldChoice
                key={`${index}-${choice.value}`}
                id={`${index}-${choice.value}`}
                type={type}
                name={type === "radio" || type === "check" ? name : choice.name}
                {...choice}
              />
            ))}
          </div>
        );
    }
  }, [name, choices, type, disabled, id, required]);

  return (
    <div className="field">
      {label ? (
        <label htmlFor={id} className="dark:text-white">
          {label}
        </label>
      ) : null}
      {choice()}
      {message ? <p className="dark:text-white">{message}</p> : null}
    </div>
  );
};

FieldChoices.propTypes = {
  type: PropTypes.oneOf(["select", "radio", "check"]),
  id: PropTypes.any,
  name: PropTypes.string,
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
      label: PropTypes.string,
      message: PropTypes.string,
      selected: PropTypes.bool,
      disabled: PropTypes.bool,
      required: PropTypes.bool,
    }),
  ),
  label: PropTypes.string,
  message: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  multiple: PropTypes.bool,
};

FieldChoices.defaultProps = {
  type: "select",
  choices: [],
};

export default FieldChoices;
