import React from "react";
import propTypes from "prop-types";

function Container(props) {
  return (
    <div className="dark:dark-mode">
      {props.path ? <div className="mb-2">{props.path}</div> : ""}
      {props.title ? <h1 className="text-4xl my-3">{props.title}</h1> : ""}
      <div className="w-full bg-white p-4 dark:dark-mode">{props.content}</div>
    </div>
  );
}

Container.propTypes = {
  path: propTypes.string,
  title: propTypes.string,
  content: propTypes.string,
};

export default Container;
