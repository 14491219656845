import { createSlice } from "@reduxjs/toolkit";

import store from "../store";
import { nanoid } from "nanoid";

const getNotificationHistory = () => {
  const history = localStorage.getItem("notification_history");
  if (!history) {
    return [];
  }
  return JSON.parse(history);
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState: {
    value: [],
    history: getNotificationHistory(),
    showModal: false,
    historyMax: 50,
  },
  reducers: {
    addAuto: (state, notification) => {
      const item = {
        nanoid: nanoid(),
        time: new Date().getTime(),
        ...notification.payload,
      };
      const timeout = notification.payload.timeout ?? 6000;
      state.value.push(item);
      state.history.push(item);

      if (state.history.length > state.historyMax) {
        state.history = state.history.splice(1, state.historyMax);
      }

      localStorage.setItem(
        "notification_history",
        JSON.stringify(state.history),
      );
      setTimeout(() => {
        store.dispatch({ type: "notification/removeAuto", payload: item });
      }, timeout);
    },
    removeAuto: (state, data) => {
      state.value = state.value.filter((item) => {
        return item.nanoid !== data.payload.nanoid;
      });
    },
    toggleModal: (state) => {
      state.showModal = !state.showModal;
    },
  },
});

// Action creators are generated for each case reducer function
export const { add, remove, toggleModal } = notificationSlice.actions;

export default notificationSlice.reducer;
