import React, { useEffect } from "react";
import { useSelector } from "react-redux";

function TitleContainer() {
  const title = useSelector((state) => state.title.value);

  useEffect(() => {
    document.title = title;
  }, [title]);

  return <></>;
}

export default TitleContainer;
