import React, { useState, useEffect, useRef } from "react";
import instance from "../../axiosConfig";
import { Base64 } from "js-base64";
import Button from "../interactive/Button";
import MarkDown from "../markdown/MarkDown";
import { XCircle } from "react-feather";
import { nanoid } from "nanoid";
import propTypes from "prop-types";

const FieldSelectSearch = ({
  type,
  name,
  value,
  label,
  message,
  endPoint,
  actions,
  ...props
}) => {
  const id = useRef(nanoid());
  // Search delay in ms.
  const delay = 200;
  const searchEndPoint = endPoint || "/search/item";
  const [input] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [selected, setSelected] = useState(value);
  const [searchData, setSearchData] = useState({ body: [] });
  let abortController = new window.AbortController();

  const delayChange = (e) => {
    const delayDebounceFn = setTimeout(() => {
      if (e.target.value === "" || e.target.value === null) {
        setSearchData({ body: [] });
        return;
      }
      setSearchTerm(e.target.value);
    }, delay);
    return () => clearTimeout(delayDebounceFn);
  };

  useEffect(() => {
    if (searchTerm === "") {
      return;
    }

    abortController.abort();
    abortController = new window.AbortController();

    instance
      .get(searchEndPoint, {
        signal: abortController.signal,
        params: {
          filters: Base64.encode(JSON.stringify({ name: searchTerm })),
        },
      })
      .then((response) => {
        setSearchData(response.data.results);
      })
      .catch((response) => {
        console.error("Error in search", response);
      });
  }, [searchTerm, searchEndPoint]);

  const handleSelectAction = (data) => {
    setSelected(data);
  };

  return (
    <div className="field">
      {label ? (
        <label htmlFor={id} className="dark:text-white">
          {label}
        </label>
      ) : null}
      <input type="hidden" value={selected._id} name={name} id={id} />
      <input
        key={input}
        autoFocus
        defaultValue={input}
        type={type}
        onChange={delayChange}
        className="dark:bg-zinc-700"
      />
      {message ? <p className="dark:text-white">{message}</p> : null}
      {selected && selected._id !== "" && (
        <div className="flex flex-wrap" style={{ maxWidth: "300px" }}>
          <div className="w-2/5 flex-auto">
            <button onClick={(e) => setSelected({ _id: "", name: "" })}>
              <div className="inline-flex ml-2 text-xs items-center font-bold leading-sm uppercase bg-red-200 text-red-700 rounded-full">
                <span className="m-1">{selected.name}</span>
                <XCircle className="m-0.5" />
              </div>
            </button>
          </div>
        </div>
      )}

      <div>
        <table>
          <tbody>
            {searchData.body.map((item, index) => {
              return (
                <tr key={index}>
                  {Object.keys(item).map((data, key) => {
                    if (data === "_id") {
                      if (actions && actions.length > 0) {
                        return actions.map((action, key) => {
                          if (action.type === "select") {
                            return (
                              <td key={key}>
                                <Button
                                  key={key}
                                  feel={action.feel}
                                  text={action.text}
                                  onClick={() => console.log("hello")}
                                />
                              </td>
                            );
                          }
                          return (
                            <td key={key}>
                              <Button
                                key={key}
                                feel={action.feel}
                                text={action.text}
                                onClick={() => console.log("hello")}
                              />
                            </td>
                          );
                        });
                      } else {
                        return (
                          <td key={key}>
                            <Button
                              key={key}
                              feel="primary"
                              text="Valitse"
                              onClick={() => handleSelectAction(item)}
                            />
                          </td>
                        );
                      }
                    }
                    return (
                      <td key={key}>
                        <MarkDown>{item[data]}</MarkDown>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

FieldSelectSearch.propTypes = {
  type: propTypes.string,
  name: propTypes.string,
  value: propTypes.oneOfType([propTypes.object, propTypes.string]),
  label: propTypes.string,
  message: propTypes.string,
  endPoint: propTypes.string,
  actions: propTypes.array,
};

export default FieldSelectSearch;
