import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import instance from "../../axiosConfig";

const feels = [
  "primary",
  "secondary",
  "tertiary",
  "directive",
  "dangerous",
  "blue",
  "indigo",
  "purple",
  "success",
  "green",
  "red",
  "danger",
  "pagination-current",
  "pagination",
  "toggle",
  "pagination-gable",
  "pagination-gable-end",
  "warning",
];

function Button({ feel, type, ...props }) {
  let classname =
    "button" +
    (feel && feels.includes(feel.toLowerCase())
      ? ` -${feel.toLowerCase().split("-").join(" -")}`
      : "");
  if (feel && feel.includes("pagination")) {
    classname =
      feel && feels.includes(feel.toLowerCase())
        ? ` -${feel.toLowerCase().split("-").join(" -")}`
        : "";
  }
  if (feel && feel === "toggle") {
    classname = "-toggle";
  }

  const Tag = type === "link" ? "a" : "button";

  const handleActionOperation = (e) => {
    e.preventDefault();
    instance({
      method: props.link["hydra:method"],
      url: props.link["@id"],
    })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  if (typeof props.link === "object") {
    return (
      <button
        id={typeof props.id !== "undefined" ? props.id.toString() : undefined}
        key={typeof props.id !== "undefined" ? props.id : undefined}
        data-operation-index={
          typeof props.operationIndex !== "undefined"
            ? props.operationIndex.toString()
            : undefined
        }
        className={classname}
        onClick={handleActionOperation}
        disabled={props.disabled}
        type={type}
        aria-label={props.ariaLabel}
      >
        {props.text}
      </button>
    );
  }

  if (type === "link") {
    return (
      <Link
        to={props.link}
        id={typeof props.id !== "undefined" ? props.id.toString() : undefined}
        key={typeof props.id !== "undefined" ? props.id : undefined}
        data-operation-index={
          typeof props.operationIndex !== "undefined"
            ? props.operationIndex.toString()
            : undefined
        }
        className={classname}
        onClick={props.onClick}
        disabled={props.disabled}
        aria-label={props.ariaLabel}
      >
        {props.text}
      </Link>
    );
  }

  return (
    <Tag
      id={typeof props.id !== "undefined" ? props.id.toString() : undefined}
      key={typeof props.id !== "undefined" ? props.id : undefined}
      data-operation-index={
        typeof props.operationIndex !== "undefined"
          ? props.operationIndex.toString()
          : undefined
      }
      className={classname}
      href={type === "link" && props.link ? props.link : null}
      onClick={props.onClick}
      disabled={props.disabled}
      type={type}
      aria-label={props.ariaLabel}
    >
      {props.text}
    </Tag>
  );
}

Button.propTypes = {
  id: PropTypes.any,
  operationIndex: PropTypes.number,
  text: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element]),
  type: PropTypes.oneOf(["button", "submit", "reset", "link"]),
  feel: PropTypes.oneOf(feels),
  onClick: PropTypes.func,
  link: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disabled: PropTypes.bool,
  ariaLabel: PropTypes.string,
};

Button.defaultProps = {
  type: "button",
  disabled: false,
};

export default Button;
