import { createSlice } from "@reduxjs/toolkit";

const darkModeSlice = createSlice({
  name: "darkMode",
  initialState: {
    theme: localStorage.theme,
  },
  reducers: {
    dark: (state) => {
      state.theme = "dark";
    },
    light: (state) => {
      state.theme = "light";
    },
  },
});

export const { dark, light } = darkModeSlice.actions;

export default darkModeSlice.reducer;
