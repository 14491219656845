import React from "react";
import SortButton from "./SortButton";
import PropTypes from "prop-types";

function Column({ name, title, sortable, colspan, ...props }) {
  return (
    <th colSpan={colspan}>
      <div className="flex justify-between items-center">
        {title}
        {sortable && <SortButton name={name} title={title} {...props} />}
      </div>
    </th>
  );
}

Column.propTypes = {
  title: PropTypes.string,
  sortable: PropTypes.bool,
  colspan: PropTypes.number,
  name: PropTypes.string,
};

Column.defaultProps = {
  title: "",
  sortable: false,
  colspan: 1,
  onSortChange: () => {},
};

export default Column;
