import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import store from "../../store/store";

function RedirectContainer() {
  const dispatch = useDispatch();
  const redirect = useSelector((state) => state.redirect.value);
  const [redirectPath, setRedirectPath] = useState(null);
  const history = useHistory();

  useEffect(() => {
    if (redirect !== null) {
      if (redirect.payload.content === redirectPath) {
        setRedirectPath(null);
      } else if (redirect.payload.content === "back") {
        store.dispatch({ type: "loading/loading" });
        history.goBack();
      } else {
        setRedirectPath(redirect.payload.content);
        store.dispatch({ type: "loading/loading" });
        dispatch({ type: "redirect/unset" });
      }
    }
  }, [redirect, dispatch, redirectPath]);

  if (redirectPath != null) {
    return <Redirect push to={redirectPath} from="nill" />;
  }
  return <div />;
}

export default RedirectContainer;
