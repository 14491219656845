import React from "react";

function Loading(props) {
  return (
    <div className="animate-pulse cursor-wait w-full h-full">
      <div className="mb-2">
        <div className="bg-gray-500 h-4 w-8 inline-block rounded-sm " />
        <div className="bg-gray-500 h-4 w-8 inline-block ml-2 rounded-sm " />
        <div className="bg-gray-400 h-4 w-8 inline-block ml-2 rounded-sm " />
      </div>
      <div className="bg-gray-600 h-10 w-56 max-w-full inline-block rounded-sm my-3" />
      <div className="grid grid-cols-7 gap-2 max-w-full w-96">
        {/* Row 1 */}
        <div className="bg-gray-600 h-4 block rounded-sm col-span-2" />
        <div className="bg-gray-600 h-4 block rounded-sm " />
        <div className="bg-gray-600 h-4 block rounded-sm " />
        <div className="bg-gray-600 h-4 block rounded-sm col-span-3" />
        {/* Row 2 */}
        <div className="bg-gray-600 h-4 block rounded-sm " />
        <div className="bg-gray-600 h-4 block rounded-sm " />
        <div className="bg-gray-600 h-4 block rounded-sm col-span-4" />
        <div className="bg-gray-600 h-4 block rounded-sm " />
        {/* Row 3 */}
        <div className="bg-gray-600 h-4 block rounded-sm " />
        <div className="bg-gray-600 h-4 block rounded-sm " />
        <div className="bg-gray-600 h-4 block rounded-sm " />
        <div className="bg-gray-600 h-4 block rounded-sm " />
        <div className="bg-gray-600 h-4 block rounded-sm " />
        <div className="bg-gray-600 h-4 block rounded-sm col-span-2" />
        {/* Row 4 */}
        <div className="bg-gray-600 h-4 block rounded-sm col-span-3" />
        <div className="bg-gray-600 h-4 block rounded-sm col-span-2" />
        <div className="bg-gray-600 h-4 block rounded-sm" />
        <div className="bg-gray-600 h-4 block rounded-sm" />
      </div>
    </div>
  );
}

export default Loading;
