import React from "react";

import "./Navigation.css";
import propTypes from "prop-types";

function NavTitle({ value }) {
  return <li className="uppercase m-2 block font-light">{value}</li>;
}

NavTitle.propTypes = {
  value: propTypes.string,
};
export default NavTitle;
