import React, { useEffect, useState } from "react";
import Column from "./Column";
import Button from "../../interactive/Button";
import propTypes from "prop-types";

const feels = [
  "primary",
  "secondary",
  "tertiary",
  "directive",
  "dangerous",
  "blue",
  "indigo",
  "purple",
  "success",
  "green",
  "red",
  "danger",
  "warning",
  "yellow",
  "pagination-current",
  "pagination",
  "toggle",
  "pagination-gable",
  "pagination-gable-end",
];

function Row({
  editable,
  selectable,
  id,
  columns,
  bodyInfo,
  onSave,
  feel,
  ...props
}) {
  const [editState, setEditState] = useState(false);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    setData(columns);
  }, [columns]);

  useEffect(() => {
    if (props.selectedList.includes(id)) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [props.selectedList, id]);

  const feelClassName = (feel) => {
    if (feels.includes(feel)) {
      return feel.toLowerCase();
    }
  };

  const HandleSave = () => {
    onSave({ id, content: data });
    setEditState(false);
  };

  const HandleSelect = () => {
    if (!selectable) {
      return;
    }
    setSelected(!selected);
    props.onSelectChange(id);
  };

  const HandleChange = (e, index) => {
    const newData = data;
    newData[index] = e;
    setData(newData);
  };

  return (
    <tr className={feelClassName(feel)}>
      {selectable && (
        <Column type="select" selected={selected} onClick={HandleSelect} />
      )}
      {data.map((column, index) => {
        if (editable && editState) {
          return (
            <Column
              key={index}
              type="edit"
              bodyInfo={bodyInfo[index]}
              column={column}
              onChange={(e) => HandleChange(e, index)}
            />
          );
        }
        return (
          <Column
            key={index}
            type="normal"
            bodyInfo={bodyInfo[index]}
            column={column}
          />
        );
      })}
      {editable && !editState && (
        <td>
          <Button
            text="Muokkaa"
            feel="primary"
            onClick={() => setEditState(true)}
          />
        </td>
      )}
      {editable && editState && (
        <td>
          <Button text="Tallenna" feel="primary" onClick={HandleSave} />
        </td>
      )}
    </tr>
  );
}

Row.propTypes = {
  id: propTypes.string,
  columns: propTypes.array,
  editable: propTypes.bool,
  selectable: propTypes.bool,
  bodyInfo: propTypes.array,
  selectedList: propTypes.array,
  onSelectChange: propTypes.func,
  onSave: propTypes.func,
  feel: propTypes.string,
};

Row.defaultProps = {
  id: "",
  feel: "",
  columns: [],
  editable: false,
  selectable: false,
  bodyInfo: [],
  selectedList: [],
  onSelectChange: (e) => {
    console.log(e);
  },
  onSave: () => {},
};

export default Row;
