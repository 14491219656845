import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { dark, light } from "../store/features/darkmode";

export default function useThemeHook() {
  const dispatch = useDispatch();
  const [theme, setTheme] = useState(localStorage.theme);
  const colorTheme = theme === "dark" ? "light" : "dark";

  if (theme === undefined) {
    if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
      setTheme("dark");
      localStorage.setItem("theme", "dark");
    } else {
      localStorage.setItem("theme", "light");
    }
  }

  useEffect(() => {
    const root = window.document.documentElement;

    root.classList.remove(colorTheme);
    root.classList.add(theme);

    if (theme === "light") {
      dispatch(light());
    } else {
      dispatch(dark());
    }

    // save theme to local storage
    localStorage.setItem("theme", theme);
  }, [theme, colorTheme]);

  return [colorTheme, setTheme];
}
