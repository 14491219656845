import React, { useState } from "react";
import * as Icon from "react-feather";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import "./Navigation.css";
import NavIcon from "./NavIcon";

function NavMenu({ link, value, icon, links }) {
  const [toggled, setToggled] = useState(false);

  const handleToggle = (e) => {
    e.preventDefault();
    setToggled(!toggled);
  };

  return (
    <li className="block list-none">
      <div className="grid grid-cols-1 auto-cols-max grid-flow-col items-center justify-between bg-gray-100 relative m-2 hover:bg-gray-200">
        <Link
          to={link}
          className={
            "grid grid-cols-1 grid-flow-col items-center justify-between w-full text-black p-2 hover:text-black hover:no-underline dark:dark-mode dark:hover:text-white dark:hover:bg-zinc-900" +
            (links ? " px-10" : " pl-10")
          }
        >
          <NavIcon icon={icon} />
          <span>{value}</span>
        </Link>
        <div
          className="h-full hover:bg-gray-300 dark:hover:bg-zinc-900"
          onClick={handleToggle}
        >
          {toggled && (
            <Icon.ChevronUp
              className={`w-9 h-full p-2 right-0 cursor-default dark:dark-mode dark:hover:bg-zinc-900 ${
                toggled ? "nav-untoggled" : ""
              }`}
            />
          )}
          {!toggled && (
            <Icon.ChevronDown
              className={`w-9 h-full p-2 right-0 cursor-default dark:dark-mode dark:hover:bg-zinc-900 ${
                !toggled ? "" : "nav-toggled"
              }`}
            />
          )}
        </div>
      </div>
      <ul className={`px-2 ml-5 ${toggled ? "" : "hidden"}`}>
        {links.map((link, index) => {
          return (
            <li key={index} className="list-none">
              <Link
                to={link.url}
                className="block m-2 p-2 hover:bg-gray-50 text-black hover:text-black hover:no-underline dark:dark-mode dark:hover:bg-zinc-900 dark:hover:text-white"
              >
                {link.value}
              </Link>
            </li>
          );
        })}
      </ul>
    </li>
  );
}

NavMenu.propTypes = {
  link: PropTypes.string,
  value: PropTypes.string,
  icon: PropTypes.string,
  links: PropTypes.array,
};

NavMenu.defaultProps = {
  link: "/service-three",
  value: "Service three with longer name",
  icon: "volume1",
  links: [
    {
      value: "Service service one",
      url: "/service-three/one",
      type: "link",
    },
  ],
};

export default NavMenu;
