import React, { useEffect, useState } from "react";
import Row from "./Row";
import RowPlaceHolder from "./RowPlaceHolder";
import PropTypes from "prop-types";
import Column from "../head/Column";

function TBody({ ...props }) {
  const [body, setBody] = useState([]);

  useEffect(() => {
    setBody(props.body);
  }, [props.body]);

  if (body.length < 1) {
    return (
      <tbody>
        {Array(5)
          .fill(0)
          .map((row, index) => (
            <RowPlaceHolder key={index} data={row} {...props} />
          ))}
      </tbody>
    );
  }

  return (
    <tbody>
      {body.map((row, index) => (
        <Row
          key={index}
          feel={row.feel ?? ""}
          columns={row.content}
          id={row.id}
          {...props}
        />
      ))}
    </tbody>
  );
}

TBody.propTypes = {
  body: PropTypes.array,
};

TBody.defaultProps = {
  body: [],
  head: [],
  select: false,
  selectedList: [],
  onSelectChange: () => {},
};

export default TBody;
