import React from "react";
import PropTypes from "prop-types";
import { useBarcode } from "react-barcodes";

/** https://github.com/Bunlong/react-barcodes */
function Ean13({ value }) {
  const validEan = (value) => {
    if (typeof value !== "string") return false;
    if (value.length !== 13) return false;
  };

  /* For testing only, generate random 12 numbers to show something */
  if (!validEan(value)) {
    value = Math.random().toString().slice(2, 14);
  }

  const { inputRef } = useBarcode({
    value,
    options: {
      format: "EAN13",
      textMargin: 0,
    },
  });

  return <img ref={inputRef} alt="ean13" />;
}

Ean13.propTypes = {
  value: PropTypes.string,
};

Ean13.defaultProps = {
  value: "123456789012",
};

export default Ean13;
