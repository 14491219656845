import { createSlice } from "@reduxjs/toolkit";

export const redirectSlice = createSlice({
  name: "redirect",
  initialState: {
    value: null,
  },
  reducers: {
    set: (state, redirect) => {
      state.value = redirect;
    },
    unset: (state) => {
      state.value = null;
    },
  },
});

export const { redirect } = redirectSlice.actions;

export default redirectSlice.reducer;
