import React from "react";
import PropTypes from "prop-types";
import Fields from "./../FormFields";

/* Button */
import Button from "./Button";

function Filters(props) {
  if (!props.form) {
    console.error("No form found for filters.");
    return "";
  }

  return (
    <div
      className={
        "z-10 fixed h-screen w-full max-h-screen left-0 top-0 bg-gray-50 bg-opacity-95 dark:bg-opacity-20" +
        (props.display ? "" : " hidden")
      }
    >
      <div
        onClick={props.handleClose}
        className="absolute h-full w-full left-0 top-0"
      />
      <div
        className="fixed h-screen max-h-screen right-0 top-0 p-4 bg-white shadow-lg overflow-auto dark:bg-zinc-700"
        style={{ minWidth: "20vw" }}
      >
        <form id={props.id} onSubmit={props.handleApply}>
          <h2>{props.title}</h2>
          <div>
            <Fields fields={props.fields} />
            <Button type="submit" feel="green" text={props.title} />
          </div>
          <div className="absolute top-0 right-0 p-4">
            <Button type="submit" feel="green" text={props.title} />
          </div>
        </form>
      </div>
    </div>
  );
}

Filters.propTypes = {
  form: PropTypes.object,
  title: PropTypes.string,
  id: PropTypes.string,
  _cancel: PropTypes.string,
  _clear: PropTypes.string,
  info: PropTypes.string,
  display: PropTypes.bool,
  handleClose: PropTypes.func,
  handleApply: PropTypes.func,
  fields: PropTypes.array,
};

Filters.defaultProps = {
  form: {},
  title: "Rajaa",
  _cancel: "Peruuta",
  _clear: "Nollaa",
  info: "%total suodatinta aktiivisena",
  display: false,
};

export default Filters;
