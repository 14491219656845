import React from "react";
import THead from "./head/THead";
import TBody from "./body/TBody";
import propTypes from "prop-types";

const Table = ({ hideHead, ...props }) => {
  return (
    <table className="w-full max-w-full overflow-auto">
      <THead hide={hideHead} {...props} />
      <TBody {...props} />
    </table>
  );
};

Table.propTypes = {
  hideHead: propTypes.bool,
};

Table.defaultProps = {
  head: [],
  body: [],
  tableType: "normal",
  hideHead: false,
  sorted: {
    name: "name",
    title: "Nimi",
    sortable: true,
  },
  onSelectChange: (list) => {
    console.table(list);
  },
  onSortChange: (name) => {
    console.log(name);
  },
};

export default Table;
