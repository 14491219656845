import React from "react";
import propTypes from "prop-types";

function Keyfigures(props) {
  if (!Array.isArray(props.figures)) {
    return null;
  }
  return props.figures.map((figure, index) => {
    return (
      <div
        className="text-center p-4 md:w-1/4 sm:w-1/2 w-full"
        key={index}
        id={index.toString()}
      >
        <div className="border-2 border-gray-200 px-4 py-6 rounded-lg">
          <h2 className="title-font font-medium text-3xl text-gray-900 dark:text-white">
            {figure.heading}
          </h2>
          <p className="leading-relaxed">{figure.value}</p>
        </div>
      </div>
    );
  });
}

Keyfigures.propTypes = {
  figures: propTypes.array,
};

export default Keyfigures;
