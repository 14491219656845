import React from "react";
import PropTypes from "prop-types";

import "./Navigation.css";

import NavTitle from "./NavTitle";
import NavImage from "./NavImage";
import NavMenu from "./NavMenu";

function Navigation({ nav }) {
  return (
    <ul
      className="w-72 relative p-2 border-r h-full max-h-screen overflow-y-auto overflow-x-hidden"
      style={{ gridArea: "nav" }}
    >
      {nav.map((item, index) => {
        switch (item.type) {
          case "title":
            return <NavTitle value={item.value} key={index} />;
          case "menu": {
            return (
              <NavMenu
                link={item.link}
                value={item.value}
                icon={item.icon}
                links={item.links}
                key={index}
              />
            );
          }
          case "image": {
            return (
              <NavImage
                source={item.source}
                alt={item.alt}
                size={item.size}
                link={item.link}
                key={index}
              />
            );
          }
          default:
            console.log(item.type);
            return "";
        }
      })}
    </ul>
  );
}

Navigation.propTypes = {
  nav: PropTypes.array,
};

Navigation.defaultProps = {
  nav: [{}],
};

export default Navigation;
