import pkg from "./../../package.json";
import instance from "../axiosConfig.js";

function knupu() {
  if (!window)
    return console.error(
      "Window does not exists, check that you are using browser.",
    );

  class Knupu {
    constructor(version) {
      this.version = version;
      this.serverVersion = undefined;
      console.log(
        `Hi 👋 Knupu [${version}] here, starting landing to window.knupu`,
      );
      instance.defaults.headers.common.Client = `Knupu-Web ${version}`;
    }
  }

  window.knupu = new Knupu(pkg.version);
}

export default knupu;
