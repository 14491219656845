import React, { useState, useEffect } from "react";
import instance from "../axiosConfig.js";

import Navigation from "../elements/navigation/Navigation";
import TopBar from "./../elements/TopBar.js";
import PageGen from "./../elements/PageGen.js";
import { Redirect, useLocation } from "react-router-dom";
import { Base64 } from "js-base64";
import Cookies from "js-cookie";
import NotificationContainer from "../elements/notifications/NotificationContainer";
import RedirectContainer from "../elements/navigation/RedirectContainer";
import NotificationModal from "../elements/notifications/NotificationModal";
import TitleContainer from "../elements/title/TitleContainer";
import store from "../store/store";
import { useSelector } from "react-redux";

function Page() {
  const loadingState = useSelector((state) => state.loading.value);
  const location = useLocation();
  const [nav, setNav] = useState([]);
  const [topBar, setTopBar] = useState([]);
  const [view, setView] = useState({
    type: "client-loading",
    data: {},
  });

  const validLogin = () =>
    window.localStorage.getItem("currentHost") &&
    Cookies.get(window.localStorage.getItem("currentHost"))
      ? JSON.parse(
          Base64.decode(
            Cookies.get(window.localStorage.getItem("currentHost")).split(
              ".",
            )[1],
          ),
        ).exp > Math.round(new Date().getTime() / 1000)
      : false;

  useEffect(() => {
    /* No errors after logout */
    if (!validLogin()) return;
    store.dispatch({ type: "loading/loading" });
    const pathname = location.pathname;
    const path = pathname === "/" || !pathname ? "/index" : pathname;
    instance
      .get(`/view${path}`)
      .then(function (response) {
        setView(response.data.view);
        store.dispatch({ type: "loading/done" });
      })
      .catch(function (error) {
        console.log(error);
        setView({
          type: "page",
          data: {
            style: "modal",
            modal: {
              title: "Page not found",
              message:
                "or the instance has a problem to with it - maybe it’s windswept.",
              cancelText: "Go back",
              feel: "tertiary",
              icon: "Wind",
              error: error.response.data.message
                ? error.response.data.message
                : null,
            },
          },
        });
      });
  }, [location]);

  useEffect(() => {
    if (nav.length === 0) {
      instance
        .get("/misc/navigation")
        .then(function (response) {
          setNav(response.data.navigation);
          setTopBar(response.data.topBar);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [nav]);

  if (!validLogin()) {
    return <Redirect to="/login" />;
  }

  return (
    <div
      className="grid bgrid-cols-2 min-h-screen dark:dark-mode"
      style={{
        gridTemplateAreas: '"nav topbar" "nav page"',
        gridTemplateColumns: "min-content auto",
        gridTemplateRows: "3.5rem auto",
      }}
    >
      <NotificationContainer />
      <NotificationModal />
      <RedirectContainer />
      <TitleContainer />
      <Navigation nav={nav} />
      <TopBar topBar={topBar} />
      <div
        className="w-full p-4 bg-indigo-50 bg-opacity-50 dark:dark-mode"
        style={{ gridArea: "page" }}
      >
        <div
          className={`ease-in-out duration-500 ${
            loadingState ? "blur-sm" : ""
          }`}
        >
          <PageGen {...view} />
        </div>
      </div>
    </div>
  );
}

export default Page;
