import React from "react";
import PropTypes from "prop-types";
import Button from "./Button";
import * as Icon from "react-feather";
import { useHistory } from "react-router-dom";

const feels = [
  "primary",
  "secondary",
  "tertiary",
  "directive",
  "dangerous",
  "blue",
  "indigo",
  "purple",
  "success",
  "green",
  "red",
  "danger",
  "pagination-current",
  "pagination",
  "toggle",
  "pagination-gable",
  "pagination-gable-end",
];

function Modal({
  title,
  message,
  actions,
  icon,
  feel,
  cancelText,
  applyText,
  handleApply,
  handleCancel,
  error,
  ...props
}) {
  const history = useHistory();
  const iconClass =
    "icon" +
    (feel && feels.includes(feel.toLowerCase())
      ? ` -${feel.toLowerCase().split("-").join(" -")}`
      : "");
  const ico = (icon) => {
    if (typeof icon !== "string") return "";
    if (icon.includes("-")) return <Icon.Hash />;
    const Ico = Icon[icon.charAt(0).toUpperCase() + icon.slice(1)];
    return <Ico />;
  };

  return (
    <div className="ease-out duration-300 inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div className="sm:flex sm:items-start">
          <div className={iconClass}>{ico(icon)}</div>
          <div
            className={
              "mt-3 text-center sm:mt-0 sm:text-left" + (icon ? " sm:ml-4" : "")
            }
          >
            {title ? (
              <h3
                className="text-lg leading-6 font-medium text-gray-900"
                id="modal-title"
              >
                {title}
              </h3>
            ) : null}
            {message ? (
              <div className="mt-2">
                <p className="text-sm text-gray-500">{message}</p>
              </div>
            ) : null}
            {error ? (
              <pre className="mt-2">
                <code>{error}</code>
              </pre>
            ) : null}
          </div>
        </div>
      </div>
      <div className="bg-gray-50 px-4 py-3 flex justify-between sm:px-6 lg:justify-end">
        {applyText && (
          <Button feel={feel} text={applyText} onClick={handleApply} />
        )}
        <Button
          feel="toggle"
          text={cancelText || "Back"}
          onClick={
            !handleCancel
              ? () => {
                  history.goBack();
                }
              : handleCancel
          }
        />
      </div>
    </div>
  );
}

Modal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  cancelText: PropTypes.string,
  applyText: PropTypes.string,
  handleCancel: PropTypes.func,
  handleApply: PropTypes.func,
  error: PropTypes.string,
  icon: PropTypes.string,
  feel: PropTypes.oneOf(feels),
  actions: PropTypes.array,
};

Modal.defaultProps = {};

export default Modal;
