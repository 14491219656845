import { configureStore } from "@reduxjs/toolkit";
import notificationReducer from "./features/notification";
import redirectReducer from "./features/redirect";
import logoutReducer from "./features/logout";
import titleReducer from "./features/title";
import darkModeReducer from "./features/darkmode";
import loadingReducer from "./features/loading";

export default configureStore({
  reducer: {
    notification: notificationReducer,
    redirect: redirectReducer,
    logout: logoutReducer,
    title: titleReducer,
    darkMode: darkModeReducer,
    loading: loadingReducer,
  },
});
