import React, { useEffect, useState } from "react";
import Button from "../../interactive/Button";
import Filters from "../../interactive/Filters";
import _, { forEach } from "underscore";

import { nanoid } from "nanoid";
import { CSVLink } from "react-csv";
import propTypes from "prop-types";
import { useHistory, useLocation } from "react-router-dom";

function TableFilters({ ...props }) {
  const history = useHistory();
  const location = useLocation();
  const onFilterChange = props.onFilterChange;
  const onParamChange = props.onParamChange;
  const params = props.params;
  const [perPage, setPerPage] = useState(params.perpage ?? 50);
  const [filters, setFilters] = useState(props.filters_data);
  const [filtersKey, setFilterKey] = useState(nanoid());
  const [filtersOpen, setFiltersOpen] = useState(false);

  const countParamsFilters =
    _.size(filters) + // <- Notice this plus here
    _.size(
      _.pick(props.params, (value, key) => {
        // Do not count perpage and orderby (that there will be no double count or ordering)
        return key !== "perpage" && key !== "orderby";
      }),
    );

  const handleApply = (event) => {
    event.preventDefault();
    const form = new window.FormData(event.target);
    const fieldFilters = {};
    form.forEach(function (value, key) {
      if (!fieldFilters[key]) {
        if (value) {
          fieldFilters[key] = value;
        }
      } else {
        if (Array.isArray(fieldFilters[key])) {
          fieldFilters[key].push(value);
        } else {
          const temp = fieldFilters[key];
          fieldFilters[key] = [];
          fieldFilters[key].push(temp);
          fieldFilters[key].push(value);
        }
      }
    });
    setFilters(fieldFilters);
    setFiltersOpen(!filtersOpen);
  };

  const restoreDefault = () => {
    setFilters({});
    setFilterKey(nanoid());
    history.push({
      pathname: location.pathname,
    });
  };

  useEffect(() => {
    props.filters.forEach((filter, index) => {
      if (filter.data.name) {
        if (
          filters.hasOwnProperty(filter.data.name) &&
          (filters[filter.data.name] !== "" ||
            filters[filter.data.name] !== undefined)
        ) {
          if (
            props.filters[index].data.type === "text" &&
            props.filters[index].type === "field"
          ) {
            props.filters[index].data.value = filters[filter.data.name];
          }
          if (props.filters[index].data.type === "date") {
            props.filters[index].data.value = filters[filter.data.name];
          }
          if (props.filters[index].data.type === "number") {
            props.filters[index].data.value = filters[filter.data.name];
          }
          if (props.filters[index].type === "searchfield") {
            props.filters[index].data.table = [filters[filter.data.name]];
          }
          if (
            props.filters[index].data.type === "radio" ||
            props.filters[index].data.type === "check"
          ) {
            let choises = props.filters[index].data.choices;
            choises.forEach((choice, choiceIndex) => {
              if (filters[filter.data.name] === choice.value) {
                props.filters[index].data.choices[choiceIndex].selected = true;
                console.log(props.filters[index].data);
              } else {
                props.filters[index].data.choices[choiceIndex].selected = false;
              }
            });
          }
        }
      }
    });
  }, []);

  useEffect(() => {
    onFilterChange(filters);
  }, [filters, onFilterChange]);

  useEffect(() => {
    if (params.perpage !== perPage) {
      onParamChange({ perpage: perPage });
    }
  }, [perPage, onParamChange, params.perpage]);

  return (
    <div>
      <Filters
        key={filtersKey}
        {...props.translations.filters}
        fields={props.filters}
        display={filtersOpen}
        handleApply={handleApply}
        handleClose={() => setFiltersOpen(false)}
      />
      <div className="flex justify-between">
        <label htmlFor="perpage" className="mx-2 my-3 ">
          <select
            defaultValue={perPage}
            onChange={(e) => setPerPage(parseInt(e.target.value))}
            className="mx-1 dark:bg-zinc-700"
            id="perpage"
          >
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="250">250</option>
            <option value="500">500</option>
          </select>
        </label>
        <div className="flex items-center">
          {countParamsFilters > 0 ? (
            <p className="mr-3">
              {"%i rajausta ".replace("%i", countParamsFilters)}{" "}
              <button className="ml-2" onClick={restoreDefault}>
                Tyhjennä
              </button>
            </p>
          ) : (
            ""
          )}
          <Button text="Filters" onClick={() => setFiltersOpen(true)} />
          <CSVLink
            className="pl-4"
            data={props.csvData}
            filename="data.csv"
            onClick={props.onCSV}
            separator=";"
          >
            <Button text="Export as CSV" />
          </CSVLink>
        </div>
      </div>
    </div>
  );
}

TableFilters.propTypes = {
  onFilterChange: propTypes.func,
  onParamChange: propTypes.func,
  params: propTypes.object,
  translations: propTypes.object,
  filters: propTypes.array,
  csvData: propTypes.array,
  onCSV: propTypes.func,
  filters_data: propTypes.object,
};

export default TableFilters;
