import React, { useEffect, useState } from "react";
import MarkDown from "../../markdown/MarkDown";
import FieldChoices from "../../fields/FieldChoices";
import Image from "../../image/Image";
import Ean13 from "../../ean/Ean13";
import Button from "../../interactive/Button";
import propTypes from "prop-types";

function Column({ type, bodyInfo, selected, column, onChange, ...props }) {
  const selectedClass = selected ? "selected" : "";
  const [value, setValue] = useState("");

  const colorVariants = {
    red: "bg-red-600",
    redder: "bg-red-800",
    green: "bg-green-600",
    greener: "bg-green-800",
  };

  useEffect(() => {
    setValue(column);
  }, [column]);

  if (!bodyInfo?.editable && type === "edit") {
    type = "normal";
  }

  const checkFeel = (e) => {
    if (typeof e === "string") {
      const regex = /\[feel=\w*]/g;
      if (e.match(regex) !== null) {
        return true;
      }
    }
    return false;
  };

  const HandleChange = (e) => {
    if (e.target.type === "checkbox") {
      console.log(e.target.checked);
      setValue(e.target.checked.toString());
      onChange(e.target.checked.toString());
    } else {
      setValue(e.target.value);
      onChange(e.target.value);
    }
  };

  switch (type) {
    case "select": {
      return (
        <td className={selectedClass} onClick={props.onClick}>
          <input type="checkbox" checked={selected} onChange={() => {}} />
        </td>
      );
    }
    case "edit": {
      switch (bodyInfo.edit.type) {
        case "number": {
          return (
            <td>
              <input
                type="number"
                className="dark:bg-zinc-700"
                value={value}
                onChange={HandleChange}
              />
            </td>
          );
        }
        case "checkbox": {
          const checked = value === "true";
          return (
            <td>
              <input
                type="checkbox"
                className="dark:bg-zinc-700"
                defaultChecked={checked}
                value={value}
                onChange={HandleChange}
              />
            </td>
          );
        }
        case "select": {
          return (
            <td>
              <FieldChoices {...bodyInfo.edit} />
            </td>
          );
        }
        default: {
          return (
            <td>
              <input
                type="text"
                className="dark:bg-zinc-700"
                value={value}
                onChange={HandleChange}
              />
            </td>
          );
        }
      }
    }
    default: {
      if (bodyInfo) {
        switch (bodyInfo.type) {
          case "image": {
            return (
              <td>
                <Image
                  source={value}
                  size={{ width: 100, height: 40 }}
                  alt={column}
                />
              </td>
            );
          }
          case "ean13": {
            return (
              <td>
                <Ean13 value={value} />
              </td>
            );
          }
          case "button": {
            if (!value) {
              return null;
            }
            const buttonData = JSON.parse(value);
            return (
              <td>
                <Button
                  feel={buttonData.feel}
                  text={buttonData.text}
                  type="link"
                  link={buttonData.link}
                />
              </td>
            );
          }
          default: {
            if (checkFeel(value)) {
              const splittedValue = value.split("[feel=");
              const feel = splittedValue[1].replace("]", "");
              return (
                <td className={colorVariants[feel]}>
                  <MarkDown>{splittedValue[0]}</MarkDown>
                </td>
              );
            } else {
              return (
                <td>
                  <MarkDown>{value}</MarkDown>
                </td>
              );
            }
          }
        }
      }
      if (checkFeel(value)) {
        const splittedValue = value.split("[feel=");
        const feel = splittedValue[1].replace("]", "");
        return (
          <td className={colorVariants[feel]}>
            <MarkDown>{splittedValue[0]}</MarkDown>
          </td>
        );
      } else {
        return (
          <td>
            <MarkDown>{value}</MarkDown>
          </td>
        );
      }
    }
  }
}

Column.propTypes = {
  type: propTypes.string,
  bodyInfo: propTypes.object,
  column: propTypes.string,
  selected: propTypes.bool,
  onClick: propTypes.func,
  onChange: propTypes.func,
};

Column.defaultProps = {
  type: "normal",
  column: "",
  selected: false,
  onClick: () => {
    console.log("click");
  },
};

export default Column;
