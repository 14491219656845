import React from "react";
import { useSelector } from "react-redux";
import NotificationTable from "./NotificationTable";

const NotificationModal = () => {
  const toggleStatus = useSelector((state) => state.notification.showModal);

  if (!toggleStatus) {
    return <></>;
  }

  return (
    <>
      <div className="absolute top-16 right-16 z-50">
        <div className="border-solid border-2 border-sky-500 rounded-lg">
          {toggleStatus && <NotificationTable />}
        </div>
      </div>
    </>
  );
};

export default NotificationModal;
