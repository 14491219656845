import { createSlice } from "@reduxjs/toolkit";

export const titleSlice = createSlice({
  name: "title",
  initialState: {
    value: "Knupu",
  },
  reducers: {
    set: (state, value) => {
      state.value = value.payload;
    },
  },
});

export const { redirect } = titleSlice.actions;

export default titleSlice.reducer;
