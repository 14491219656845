import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import * as Icon from "react-feather";
import PropTypes from "prop-types";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
const FieldDragAndDropList = ({ name, label, value, endpoint, ...props }) => {
  const [items, setItems] = useState(value);

  useEffect(() => {
    return () => {
      setItems(value);
    };
  }, [value]);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return "";
    }

    const newItemList = reorder(
      items,
      result.source.index,
      result.destination.index,
    );

    setItems(newItemList);
  };

  const moveTo = (index, newIndex) => {
    const newItemList = reorder(items, index, newIndex);

    setItems(newItemList);
  };

  return (
    <div className="field" key={props.id}>
      <input type="hidden" value={JSON.stringify(items)} name={name} />
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(droppableProvided) => (
            <div ref={droppableProvided.innerRef}>
              {items.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(draggableProvided) => (
                    <div
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                      {...draggableProvided.dragHandleProps}
                    >
                      <div className="block max-w-sm bg-white rounded-lg border border-gray-200 shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                        <div className="flex">
                          <div className="flex-1 w-4">
                            <ul className="list-none p-0">
                              <Icon.ChevronsUp
                                onClick={(e) => {
                                  e.preventDefault();
                                  moveTo(index, 0);
                                }}
                              />
                              <Icon.ChevronsDown
                                onClick={(e) => {
                                  e.preventDefault();
                                  moveTo(index, items.length);
                                }}
                              />
                            </ul>
                          </div>
                          <div className="flex-1">
                            <p className="font-normal text-gray-700 dark:text-gray-400">
                              {item.content}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

FieldDragAndDropList.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.array,
  endpoint: PropTypes.string,
};

FieldDragAndDropList.defaultProps = {
  name: "",
  label: "",
  value: [],
};

export default FieldDragAndDropList;
