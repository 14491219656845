import React from "react";
import * as Icon from "react-feather";
import propTypes from "prop-types";

function SortButton({ name, title, onSortChange, orderBy, ...props }) {
  if (orderBy === name) {
    return (
      <button>
        <Icon.ChevronUp onClick={() => onSortChange(name)} />
      </button>
    );
  }
  return (
    <button>
      <Icon.ChevronDown onClick={() => onSortChange(name)} />
    </button>
  );
}

SortButton.propTypes = {
  title: propTypes.string,
  sortedBy: propTypes.string,
  name: propTypes.string,
  onSortChange: propTypes.func,
  orderBy: propTypes.string,
};

SortButton.defaultProps = {
  title: "",
  sortedBy: "",
  onSortChange: () => {},
};

export default SortButton;
